import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { getProfile } from '../services/apiService';
import { useAuthState } from '@vaidhyamegha/service-gateway-react';
import { AuthContext } from '../context/AuthContext';

const FrameComponent = ({ className = '' }) => {
  const navigate = useNavigate();
  const authState = useAuthState();
  const { logout, user } = useContext(AuthContext);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    console.log('Auth state changed:', authState.isAuthenticated);
  }, [authState.isAuthenticated]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const handleProfileNavigation = () => {
  //   getProfile(subscriberId)
  //     .then((response) => {
  //       const profileExists =
  //         response.data &&
  //         response.data.demographics &&
  //         Object.values(response.data.demographics).some((field) => field !== null);

  //       if (profileExists) {
  //         navigate('/profile');
  //       } else {
  //         navigate('/profile-update');
  //       }
  //     })
  //     .catch(() => {
  //       navigate('/profile-update');
  //     });
  // };

  const handleLogout = async () => {
    try {
      await logout();
      if (typeof authState.reset === 'function') {
        authState.reset();
      }
      navigate('/auth/login');
    } catch (err) {
      console.error('Logout Error:', err.message || 'Logout failed');
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/*
        1) Use `fixed` top-0 for the nav, with a high z-index so it's on top.
        2) We'll use lg: for the desktop layout instead of sm:.
      */}
      <div
        className={`
          bg-[#11293d] border-b-[5px] border-[#2C93DC]
          fixed top-0 left-0 w-full z-50
          box-border
          px-4 py-1 lg:px-[100px]
          flex items-center justify-between
          ${className}
        `}
      >
        {/* LOGO Section */}
        <div className='flex items-center'>
          <img
            className='h-[70px] w-[180px] lg:h-[97px] lg:w-[230px] object-contain'
            loading='lazy'
            alt='CSDLC Logo'
            src='/subscription/logo.svg'
          />
        </div>

        {/*
          Hamburger Button
          - Show on mobile (block) → hidden on md (>=768px)
        */}
        <button
          className='block lg:hidden text-white'
          onClick={toggleMobileMenu}
          aria-label='Toggle Menu'
        >
          {/* Simple 3-bars hamburger icon */}
          <svg
            className='w-10 h-16'
            fill='none'
            color='#30D5C8'
            stroke='currentColor'
            strokeWidth='2'
            viewBox='0 0 24 24'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M4 6h16M4 12h16M4 18h16'
            />
          </svg>
        </button>

        {/*
          Desktop Nav
          - Hidden by default (hidden)
          - Shown on md+ (lg:flex)
        */}
        <div className='hidden lg:flex items-center justify-end gap-4'>
          {/* Nav Items */}
          <button
            className='
              bg-transparent text-white border border-[#30D5C8]
              px-5 py-2 rounded hover:bg-[#0056b3]
              text-sm
            '
            onClick={() => navigate('/')}
          >
            HOME
          </button>

          <button
            className='
              bg-transparent text-white border border-[#30D5C8]
              px-5 py-2 rounded hover:bg-[#0056b3]
              text-sm
            '
            onClick={() => navigate('/dictionary')}
          >
            DICTIONARY
          </button>
          {/* 
          {!authState.isAuthenticated? (
            <button
              className="
                bg-transparent text-white border border-[#30D5C8]
                px-5 py-2 rounded hover:bg-[#0056b3]
                text-sm
              "
              onClick={() => navigate('/auth')}
            >
              LOGIN/REGISTER
            </button>
          ):(   
                        <button
              className="
                bg-transparent text-white border border-[#30D5C8]
                px-5 py-2 rounded hover:bg-[#0056b3]
                text-sm
              "
              onClick={handleLogout}
            >
              LOGOUT
            </button>)} */}

          {authState.isAuthenticated && (
            <div className='text-sm font-normal text-white'>
              Welcome{' '}
              <span className='text-[#30d5c8] font-bold'>{user?.name}</span> !
            </div>
          )}

          {/* User Menu */}
          {/* <svg
            className="w-[23.4px] h-[26px]"
            fill="none"
            color="#30D5C8"
            stroke="currentColor"
            strokeWidth="3"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg> */}
          <div
            className='relative'
            ref={dropdownRef}
          >
            <div
              className='flex items-end justify-end w-[30px] cursor-pointer pb-1'
              onClick={toggleDropdown}
            >
              <img
                className='w-[23.4px] h-[26px]'
                loading='lazy'
                alt='User'
                src='/subscription/user-icon.svg'
              />
            </div>

            {/* Dropdown */}
            <div
              className={
                dropdownVisible
                  ? `absolute bg-[#2C93DC] min-w-[160px]
                     shadow-[0_8px_16px_rgba(0,0,0,0.2)] z-[1]
                     right-0 top-[calc(100%+0.5rem)]
                     rounded-[4px] block`
                  : 'hidden'
              }
            >
              {!authState.isAuthenticated ? (
                <div
                  className='px-4 py-3 text-white font-bold uppercase hover:bg-[#1A73E8] border-b border-[#166DAA] cursor-pointer'
                  onClick={() => navigate('/auth')}
                >
                  LOGIN/REGISTER
                </div>
              ) : (
                <>
                  {/* <div
                    className="
                      px-4 py-3 flex items-center text-white font-bold uppercase
                      hover:bg-[#1A73E8] border-b border-[#166DAA] cursor-pointer
                    "
                    onClick={handleProfileNavigation}
                  >
                    <img
                      src="/layout/settings-icon.png"
                      alt="Settings"
                      className="w-5 h-5 mr-2"
                      aria-hidden="true"
                    />
                    MY PROFILE
                  </div> */}

                  <div
                    className='
                      px-4 py-3 flex items-center text-white font-bold uppercase
                      hover:bg-[#1A73E8] border-b border-[#166DAA] cursor-pointer
                    '
                    onClick={() => navigate('/change-password')}
                  >
                    <img
                      src='/layout/change-password-icon.png'
                      alt='Change Password'
                      className='w-5 h-5 mr-2'
                      aria-hidden='true'
                    />
                    CHANGE PASSWORD
                  </div>

                  <div
                    className='
                      px-4 py-3 flex items-center text-white font-bold uppercase
                      hover:bg-[#1A73E8] border-b border-[#166DAA] cursor-pointer
                    '
                    onClick={handleLogout}
                  >
                    <img
                      src='/layout/logout-icon.png'
                      alt='Logout'
                      className='w-5 h-5 mr-2'
                      aria-hidden='true'
                    />
                    LOGOUT
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*
        Mobile Nav Drawer
        - Shown when isMobileMenuOpen = true
        - Only for < md (i.e., default). Hidden at md.
      */}
      {isMobileMenuOpen && (
        <div className='block lg:hidden fixed top-[58px] left-0 w-full bg-[#11293d] z-40'>
          <div className='flex flex-col items-start pt-9 px-4 pb-4'>
            {authState.isAuthenticated && (
              <div className='text-white mb-1'>
                Welcome{' '}
                <span className='text-[#30d5c8] font-bold'>{user?.name}</span> !
              </div>
            )}

            <button
              className='
        text-white border-b-2 border-[#30D5C8]
          px-4 py-1  hover:bg-[#0056b3]
          w-full text-left mb-1
        '
              onClick={() => {
                navigate('/');
                setIsMobileMenuOpen(false);
              }}
            >
              HOME
            </button>

            <button
              className='
          text-white border-b-2 border-[#30D5C8]
          px-4 py-1  hover:bg-[#0056b3]
          w-full text-left mb-1
        '
              onClick={() => {
                navigate('/dictionary');
                setIsMobileMenuOpen(false);
              }}
            >
              DICTIONARY
            </button>

            {!authState.isAuthenticated ? (
              <button
                className='
        text-white border-b-2 border-[#30D5C8]
          px-4 py-1  hover:bg-[#0056b3]
          w-full text-left mb-1
          '
                onClick={() => {
                  navigate('/auth');
                  setIsMobileMenuOpen(false);
                }}
              >
                LOGIN/REGISTER
              </button>
            ) : (
              <>
                {/* <button
            className="
              text-white border border-[#30D5C8]
              px-4 py-2 rounded hover:bg-[#0056b3]
              w-full text-left mb-2
            "
            onClick={() => {
              handleProfileNavigation();
              setIsMobileMenuOpen(false);
            }}
          >
            MY PROFILE
          </button> */}

                <button
                  className='
              text-white border-b-2 border-[#30D5C8]
          px-4 py-1  hover:bg-[#0056b3]
          w-full text-left mb-1
            '
                  onClick={() => {
                    navigate('/change-password');
                    setIsMobileMenuOpen(false);
                  }}
                >
                  CHANGE PASSWORD
                </button>

                <button
                  className='
              text-white border-b-2 border-[#30D5C8]
          px-4 py-1  hover:bg-[#0056b3]
          w-full text-left mb-1
            '
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  LOGOUT
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FrameComponent;
