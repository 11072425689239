import {
  useAuthState,
  useClient,
  useServiceGateway,
} from '@vaidhyamegha/service-gateway-react';
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button'; // Adjust the path as necessary

const Dashboard = () => {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const authState = useAuthState();
  const client = useClient();
  const { requestForwarding } = useServiceGateway();

  const navigate = useNavigate();
  const handleClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/billing'); // Redirect after delay
    }, 2000);
  };


  useEffect(() => {
    const checkSubscription = async () => {
      if (authState.isAuthenticated) {
        try {
          // Check subscription status for a specific product
          await client.getSubscriptionStatus(process.env.REACT_APP_SUBSCRIPTION_PRODUCT);

          const subscriptionState = client.getSubscriptionState();
          setIsSubscribed(subscriptionState?.isActive || false);
          console.log('Subscription Active:', subscriptionState?.isActive);
        } catch (error) {
          console.error('Failed to check subscription:', error);
          setIsSubscribed(false);
        }
      } else {
        setIsSubscribed(false);
      }
    };

    checkSubscription();
  }, [authState.isAuthenticated, client]);

  useEffect(() => {
    let isMounted = true;

    const fetchReports = async () => {
      if (!requestForwarding) return;

      setLoading(true);
      try {
        const config = {
          url: '/api/s3/access-s3-files',
          method: 'GET',
          params: {
            service: 'flask-csdlc-backend',
            page: currentPage,
            page_size: itemsPerPage, // Ensure itemsPerPage is passed
          },
        };

        const [responseData] = await requestForwarding(config);
        if (!isMounted) return;

        if (!responseData?.files) {
          throw new Error('No files data received');
        }

        const formattedReports = responseData.files.map((file, index) => {
          const dateMatch = file.match(/\d{4}[_-]\d{2}[_-]\d{2}/);
          const date = dateMatch
            ? new Date(dateMatch[0].replace(/_/g, '-')).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }
              )
            : 'Unknown Date';

          return {
            id:
              responseData.pagination.totalItems -
              ((currentPage - 1) * itemsPerPage + index),
            date,
            url: file,
          };
        });

        if (isMounted) {
          setReports(formattedReports);
          setTotalItems(responseData.pagination.totalItems || 0);
          setError(null);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching S3 reports:', error);
          setError(error.message || 'Failed to fetch reports');
          setReports([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    fetchReports();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const canGoNext = currentPage < totalPages;
  const canGoPrev = currentPage > 1;

  const nextButtonSrc = canGoNext
    ? '/dashboard/next.svg'
    : '/dashboard/prev.svg';
  const prevButtonSrc = canGoPrev
    ? '/dashboard/next.svg'
    : '/dashboard/prev.svg';

  const handleNext = () => {
    if (canGoNext) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (canGoPrev) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const maxVisible = 4;
  /**
   * The function `getVisiblePages` returns an array of visible page numbers based on the current page
   * and total number of pages, and `handleTogglePopup` toggles the visibility of a popup.
   * @returns The `getVisiblePages` function returns an array of visible page numbers based on the
   * current page number (`currentPage`), total number of pages (`totalPages`), and maximum number of
   * visible pages (`maxVisible`). The returned array represents the visible page numbers that should be
   * displayed in a pagination component.
   */
  const getVisiblePages = () => {
    if (totalPages <= maxVisible) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 2) {
      return [1, 2, 3, '...', totalPages];
    }

    if (currentPage >= totalPages - 1) {
      return [1, '...', totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, currentPage, '...', totalPages];
  };
  const visiblePages = getVisiblePages();
  const handleTogglePopup = () => {
    setShowPopup((prev) => !prev);
  };

  /**
   * The `handleViewFile` function opens a file URL in a new tab with security measures.
   */
  const handleViewFile = (fileUrl) => {
    window.open(fileUrl, '_blank', 'noopener,noreferrer');
  };

  /**
   * The function `handleDownloadReport` asynchronously fetches a file from a given URL and downloads it
   * with a specified file name.
   */
  const handleDownloadReport = async (fileUrl, fileName = 'report.pdf') => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blobData = await response.blob();
      fileDownload(blobData, fileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  return (
    <div className='flex flex-col items-center w-full max-w-[1280px] bg-white min-h-screen p-4 relative mx-auto'>
      {error && (
        <div className='text-red-500 mb-4 w-full max-w-[1253px]'>
          Error loading reports: {error}
        </div>
      )}
      {/* <button
        onClick={handleTogglePopup}
        disabled={isSubscribed}
        className='absolute right-4 px-6 py-2 bg-[#0c2d56] uppercase text-white rounded md:absolute md:top-16 md:right-4 md:mt-15
          md:px-6 md:py-2 md:text-white md:rounded sm:absolute sm:top-16 sm:right-4 sm:px-6 sm:py-2 sm:text-white sm:rounded'
      >
        Subscribe Now
      </button> */}
      <div className='absolute ml-[350px] sm:absolute sm:top-16 sm:right-4 right-4'>
        {!isSubscribed && (

      <Button variant='csdlc_button' onClick={handleTogglePopup}
      
      >Subscribe Now</Button>)}</div>
      <div className='w-full max-w-[1253px] mt-[100px]'>
        <div className='text-center mb-8'>
          <h1 className='text-2xl font-semibold text-[#4299d4]'>
            Trends and Knowledge Graphs Reports
          </h1>
          <p className='text-base text-[#5b5b5b] mt-2'>
            Brief explanation of what these reports are
          </p>
        </div>

        <div className='w-full overflow-x-auto mt-5'>
          <table className='border-separate border-spacing-0 border-[1px] border-[#008a93] sm:rounded-t-md  rounded-t-md w-full'>
            <thead>
              <tr className='bg-[#01aa96] text-white '>
                <th className='px-4 py-5 sm:px-6 md:px-4 xxl:px-4 border border-[#008a93] whitespace-nowrap text-left sm:text-center md:text-left'>
                  Report
                </th>
                <th className='px-10 border py-5 border-[#008a93] whitespace-nowrap'>
                  Publish Date
                </th>
                <th className='px-8 border py-5 border-[#008a93] rounded-tr whitespace-nowrap'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan='3' className='text-center p-4'>
                    Loading...
                  </td>
                </tr>
              ) : (
                reports.map((report, index) => (
                  <tr
                    key={report.id}
                    className={`${
                      index % 2 === 0 ? 'bg-[#ceedff]' : 'bg-[#f2fffe]'
                    }`}
                  >
                    <td className='p-4 border border-[#008a93]'>
                      Clinical Trials #{report.id} - Visualization Report
                    </td>
                    <td className='p-4 border text-center border-[#008a93] whitespace-nowrap'>
                      {report.date}
                    </td>
                    <td className='p-2 border sm:mr-2 text-center border-[#008a93] whitespace-nowrap'>
                      {isSubscribed ? (
                        <>
                          <button
                            onClick={() =>
                              handleDownloadReport(
                                report.url,
                                `ClinicalTrialReport_${report.id}.pdf`
                              )
                            }
                          >
                            <img
                              src='/dashboard/download.svg'
                              alt='Download'
                              className='w-9 h-5 mr-0 sm:mr-2'
                            />
                          </button>

                          <button
                            onClick={() => handleViewFile(report.url)}
                            className='inline-block'
                          >
                            <img
                              src='/dashboard/view.svg'
                              alt='View'
                              className='ml-4 sm:mr-2 w-9 h-5'
                            />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={handleTogglePopup}
                            className='inline-block'
                          >
                            <img
                              src='/dashboard/download.svg'
                              alt='Download'
                              className='w-9 h-5 mr-0 sm:mr-2'
                            />
                          </button>
                          <button
                            onClick={handleTogglePopup}
                            className='inline-block'
                          >
                            <img
                              src='/dashboard/view.svg'
                              alt='View'
                              className='ml-4 sm:mr-2 w-9 h-5'
                            />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className='flex  gap-2 my-8 w-full'>
          <div className='flex w-1/2 items-center justify-start  mb-4'>
            <span
              className='text-[#3f3f3f] text-base font-normal leading-snug 
  sm:block hidden'
            >
              View entries per Page:
            </span>
            <span
              className='text-[#3f3f3f] text-base font-normal leading-snug 
  sm:hidden block'
            >
              Page:
            </span>

            <select
              className='w-[79px] h-10 bg-white rounded-md shadow-[inset_2px_2px_4px_0px_rgba(0,0,0,0.25)] ml-3 pl-6 gap-2 border border-[#b3e9f4]'
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              {[5, 10, 15, 50, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className='flex items-center sm:w-1/2 sm:justify-end gap-2'>
            <button
              onClick={handlePrev}
              disabled={!canGoPrev}
              className='sm:w-[31.12px] sm:h-[30px] w-8 h-5 flex items-center justify-center transition-transform duration-150 active:scale-95'
            >
              <img
                src={prevButtonSrc}
                alt='Prev'
                className={
                  canGoPrev
                    ? 'w-full h-full object-contain rotate-180'
                    : 'w-full h-full object-contain'
                }
              />
            </button>

            <div className='flex gap-2'>
              {visiblePages.map((page, index) => (
                <div
                  key={index}
                  onClick={
                    page !== '...' ? () => setCurrentPage(page) : undefined
                  }
                  className={`sm:w-[30px] sm:h-[30px] w-5 h-5 font-size-82px] flex items-center justify-center rounded-full cursor-pointer ${
                    page === currentPage
                      ? 'bg-[#47c1b2] text-white'
                      : 'bg-[#d9d9d9] text-[#9a9a9a]'
                  } ${page === '...' ? 'cursor-default' : ''}`}
                >
                  {page}
                </div>
              ))}
            </div>

            <button
              onClick={handleNext}
              disabled={!canGoNext}
              className='sm:w-[31.12px] sm:h-[30px]  w-8 h-5 flex items-center justify-center'
            >
              <img
                src={nextButtonSrc}
                alt='Next'
                className={
                  canGoNext
                    ? 'w-full h-full object-contain'
                    : 'w-full h-full object-contain rotate-180'
                }
              />
            </button>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='w-full max-w-[900px]   h-auto sm:h-64 relative bg-white rounded-md shadow-lg overflow-hidden'>
            {/* Close Button */}
            <button
              onClick={handleTogglePopup}
              className='absolute top-2 font-bold size-[24px] right-6 text-2xl text-[#004f85]'
            >
              &times;
            </button>

            {/* Title */}
            <div className='px-6 py-4 text-[#2685ce] text-lg sm:text-[28px] font-semibold'>
              Access Premium Reports
            </div>

            {/* Divider */}
            <div className='border-t border-[#2c93dc] mx-6' />

            {/* Content */}
            <div className='px-6 py-4'>
              <span className='text-black text-base font-normal leading-normal'>
                To <span className='font-bold'>Download</span> or{' '}
                <span className='font-bold'>View</span> the custom report
                "Clinical Trials Across the Globe & Visualization of Knowledge
                Graph," please subscribe to unlock full access to premium
                features.
              </span>
            </div>

    
            <div className='flex justify-center py-4'>
              {/* <button
                onClick={handleClick}
                disabled={loading}
                className='bg-[#004f85] hover:bg-[#003d66] text-white text-lg font-bold uppercase px-6 py-2 rounded flex items-center justify-center'
              > */}

                <Button onClick={handleClick}
                disabled={loading}
                variant='csdlc_button'
                >
                {loading ? (
                  <svg className='animate-spin h-5 w-5 ' viewBox='0 0 24 24'>
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    />
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z'
                    />
                  </svg>
                ) : (
                  'Subscribe Now'
                )}
          </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
