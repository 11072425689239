// src/components/PrivateRoute.js
import { useAuthState } from '@vaidhyamegha/service-gateway-react';
import { Navigate } from 'react-router-dom';
const PrivateRoute = ({ children }) => {
  const authState = useAuthState();
  // const { isAuthenticated } = useContext(AuthContext);
  console.log('PrivateRoute: isAuthenticated:', authState.isAuthenticated);
  return authState.isAuthenticated ? children : <Navigate to='/auth/login' />;
};

export default PrivateRoute;
