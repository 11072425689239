import {
  useClient,
  useIsResetPasswordLoading,
} from '@vaidhyamegha/service-gateway-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
  const client = useClient();
  const isResetPasswordLoading = useIsResetPasswordLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const resetToken = searchParams.get('token');

  useEffect(() => {
    if (!resetToken) {
      setError(
        'Invalid or missing password reset link. Please check your email.'
      );
    }
  }, [resetToken]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!newPassword || !confirmPassword) {
      setError('Please fill all required fields.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;

    if (!passwordRegex.test(newPassword)) {
      setError(
        'Password must be 8-25 characters and include an uppercase letter, lowercase letter, number, and special character.'
      );
      return false;
    }

    try {
      if (!resetToken) {
        setError('Reset token is missing or invalid.');
        return;
      }

      await client.resetPassword(resetToken, newPassword);
      setMessage('Password reset successfully. Redirecting to login...');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => navigate('/auth/login'), 2000);
    } catch (err) {
      if (err?.message?.includes('401')) {
        setError(
          'The password reset link has expired. Please request a new one.'
        );
        setIsTokenExpired(true);
      } else {
        setError(
          err.response?.data?.message ||
            'Failed to reset password. Please try again.'
        );
      }
    }
  };

  return (
    <div className='flex sm:mt-32 mt-16 justify-center w-full min-h-screen'>
      <div className='w-full max-w-xl mx-auto xl:p-0 sm:p-8 md:p-0'>
        {error && (
          <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
            <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
              {error}
            </p>
          </div>
        )}
        <div className='bg-white shadow-md rounded-lg p-8 w-full sm:max-w-xl  border-2 border-blue-300 flex flex-col items-center'>
          <h2 className='text-2xl font-bold text-[#1876be] text-center mb-6'>
            Reset Password
          </h2>

          <form onSubmit={handleResetPassword} className='w-full space-y-4'>
            <div className='flex flex-col sm:flex-row relative'>
              <label className='w-2/4 pb-2  sm:text-right sm:pr-2 text-[#2685ce] text-base font-medium'>
                New Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[20px] pr-10 rounded-md shadow-inner border 
                        ${
                          newPassword &&
                          confirmPassword &&
                          newPassword.length >= 8
                            ? newPassword === confirmPassword
                              ? 'border-green-500'
                              : 'border-red-500'
                            : 'border-[#b3e9f4]'
                        }`}
                  placeholder='Enter your New Password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div className='flex flex-col sm:flex-row relative'>
              <label className='sm:w-2/4 pb-2 pr-2 text-[#2685ce] sm:text-right text-base font-medium'>
                Confirm New Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[20px] pr-10 rounded-md shadow-inner border 
                        ${
                          newPassword &&
                          confirmPassword &&
                          newPassword.length >= 8
                            ? newPassword === confirmPassword
                              ? 'border-green-500'
                              : 'border-red-500'
                            : 'border-[#b3e9f4]'
                        }`}
                  placeholder='Enter your Password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type='button'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showConfirmPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div className='w-full flex sm:justify-center justify-start'>
              <button
                type='submit'
                disabled={isResetPasswordLoading || isTokenExpired}
                className='w-32 bg-[#1876be] text-white font-semibold py-2 sm:ml-8 rounded-md hover:bg-blue-700 transition'
              >
                {isResetPasswordLoading ? 'Resetting...' : 'Submit'}
              </button>
            </div>
            {message && (
              <div className='text-green-600 text-sm mt-4 text-center'>
                {message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
