import React, { useEffect, useMemo, useState } from 'react';
import DictionarySearch from './DictionarySearch';
import TermItem from './TermItem';
import { useTerminologyData } from './useTerminologyData';

const Dictionary = () => {
  const [inputValue, setInputValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const { terms, loading, error } = useTerminologyData();

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilterValue(inputValue);
    }, 150);
    return () => clearTimeout(timer);
  }, [inputValue]);

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) return text;
    const regex = new RegExp(`(${highlight})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span
          key={index}
          className='bg-[rgba(38,133,206,0.2)] px-1 rounded text-[#bd09b1] font-medium transition-colors duration-200 hover:bg-[rgba(51,45,221,0.3)]'
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const filteredAndSortedTerms = useMemo(() => {
    if (!filterValue) {
      return [...terms].sort((a, b) => a.Term.localeCompare(b.Term));
    }
    const searchLower = filterValue.toLowerCase();
    const filtered = terms.filter((term) =>
      term.searchableText.includes(searchLower)
    );
    return filtered.sort((a, b) => a.Term.localeCompare(b.Term));
  }, [filterValue, terms]);

  if (loading) {
    return (
      <div className='w-full min-h-[calc(100vh-97px)] flex flex-col items-center overflow-x-hidden'>
        <div className='text-center p-8 text-gray-600 bg-white rounded shadow-md my-8 max-w-[400px] mx-auto'>
          <div className='border-4 border-gray-200 border-t-[#2685CE] rounded-full w-10 h-10 mx-auto my-4 animate-spin' />
          <p>Loading terminology...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full min-h-[calc(100vh-97px)] flex flex-col items-center overflow-x-hidden'>
        <div className='text-center p-8 bg-white rounded shadow-md my-8 max-w-[400px] mx-auto text-red-600'>
          <p>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className='bg-[#2685CE] text-white border-none px-4 py-2 rounded cursor-pointer text-sm mt-4 transition-colors duration-300 hover:bg-[#1a6ca8]'
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full min-h-[calc(100vh-97px)] flex flex-col items-center overflow-x-hidden'>
      {/* Banner Section */}
      <div
        className='w-full h-[260px] relative bg-cover bg-center mb-8'
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80')",
        }}
      >
        <div
          className='absolute inset-0 flex flex-col items-center justify-center text-white text-center 
                        bg-[linear-gradient(45deg,_rgba(41,118,120,0.9),_rgba(52,142,180,0.9))] p-4 md:p-8'
        >
          <h1 className='text-4xl md:text-5xl font-semibold mb-4'>
            Clinical Trial Terminology
          </h1>
          <p className='text-lg max-w-[600px] leading-relaxed'>
            Explore our comprehensive dictionary of clinical trial terms to
            better understand medical research and healthcare studies.
          </p>
        </div>
      </div>

      {/* Main Container */}
      <div
        className='relative z-10 flex-grow w-full max-w-[1200px] -mt-[60px] mb-8 p-8 mx-auto box-border flex flex-col items-center gap-8 
                      bg-white rounded-xl shadow font-[var(--font-proxima-nova)]'
      >
        {/* Search Bar */}
        <DictionarySearch
          searchTerm={inputValue}
          setSearchTerm={setInputValue}
        />

        {/* Dictionary Content */}
        <div className='w-full flex flex-col items-center gap-8'>
          {filteredAndSortedTerms.length === 0 ? (
            <div className='text-center p-8 text-gray-600 bg-white rounded shadow-md max-w-[400px] mx-auto'>
              <p>No matching terms found for &quot;{filterValue}&quot;</p>
              <button
                onClick={() => {
                  setInputValue('');
                  setFilterValue('');
                }}
                className='bg-[#2685CE] text-white border-none px-4 py-2 rounded cursor-pointer text-sm mt-4 transition-colors duration-300 hover:bg-[#1a6ca8]'
              >
                Clear Search
              </button>
            </div>
          ) : (
            <div
              className='w-full grid gap-[60px] sm:p-8 p-0 box-border 
                         grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'
              role='region'
              aria-label='Clinical terms list'
              id='search-results'
            >
              {filteredAndSortedTerms.map((term, index) => (
                <TermItem
                  key={term.id}
                  term={term}
                  searchTerm={filterValue}
                  highlightText={highlightText}
                  index={index}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dictionary;
