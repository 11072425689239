// src/utils/analytics.js
const ANALYTICS_CONFIG = {
  measurementId: 'G-JSXPY1887B', // VaidhyaMegha's measurement ID
  linkerConfig: {
    domains: [
      'csdlc.org',
      'vaidhyamegha.com',
      'dev-vaidhyamegha.web.app',
      'aisdlc.com',
      'ct-biosdlc-com.web.app',
    ],
    accept_incoming: true,
    decorate_forms: true,
  },
  eventCategories: {
    NAVIGATION: 'navigation',
    USER_ENGAGEMENT: 'user_engagement',
    SUBSCRIPTION: 'subscription',
    CONTENT_INTERACTION: 'content_interaction',
  },
};

// Initialize analytics configuration with enhanced parameter handling
export const initializeAnalytics = () => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('config', ANALYTICS_CONFIG.measurementId, {
      allow_google_signals: true,
      linker: {
        ...ANALYTICS_CONFIG.linkerConfig,
        use_anchor: true,
        forward_client_events: true,
      },
      cookie_flags: 'SameSite=None;Secure',
      cookie_domain: 'auto',
      // Enable enhanced parameter preservation
      custom_map: {
        dimension1: 'original_referrer',
        dimension2: 'landing_page',
      },
    });

    // Initialize parameter preservation
    preserveAnalyticsParams();
  }
};

// Enhanced cross-domain navigation handler
export const handleCrossDomainNavigation = (url) => {
  if (!url) return Promise.reject(new Error('No URL provided'));

  console.log('Handling cross-domain navigation to:', url);

  return new Promise((resolve, reject) => {
    if (typeof window.gtag === 'undefined') {
      console.warn('gtag not available');
      window.open(url, '_blank');
      resolve(url);
      return;
    }

    try {
      // Track the navigation event
      window.gtag('event', 'page_view', {
        send_to: ANALYTICS_CONFIG.measurementId,
        link_url: url,
        link_domain: new URL(url).hostname,
      });

      // Use linker:passthrough for parameter persistence
      window.gtag('linker:passthrough', {
        domains: ANALYTICS_CONFIG.linkerConfig.domains,
      });

      // Decorate URL with enhanced checks
      window.gtag('linker:decorate', {
        url: url,
        success: (decoratedUrl) => {
          console.log('URL decorated successfully:', decoratedUrl);

          // Create a temporary anchor with preservation attributes
          const link = document.createElement('a');
          link.href = decoratedUrl;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';

          // Add data attributes to prevent parameter stripping
          link.setAttribute('data-gl-preserve', 'true');
          link.setAttribute('data-analytics-link', 'true');

          // Simulate natural click
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          resolve(decoratedUrl);
        },
        error: (err) => {
          console.warn('URL decoration failed:', err);
          reject(err);
        },
      });
    } catch (error) {
      console.error('Cross-domain navigation error:', error);
      reject(error);
    }
  });
};

// Helper function to preserve analytics parameters
function preserveAnalyticsParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const glParam = urlParams.get('_gl');

  if (glParam) {
    // Store _gl parameter in sessionStorage
    sessionStorage.setItem('_gl_param', glParam);

    // Add listener for history changes
    window.addEventListener('popstate', function () {
      const storedGl = sessionStorage.getItem('_gl_param');
      if (storedGl && !window.location.search.includes('_gl=')) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set('_gl', storedGl);
        window.history.replaceState({}, '', newUrl);
      }
    });
  }
}

// Track custom events with enhanced parameter handling
export const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window.gtag !== 'undefined') {
    const glParam = sessionStorage.getItem('_gl_param');
    const currentDomain = window.location.hostname;

    const enhancedParams = {
      ...eventParams,
      send_to: ANALYTICS_CONFIG.measurementId,
      domain: currentDomain,
      timestamp: new Date().toISOString(),
      ...(glParam && { _gl: glParam }),
    };

    window.gtag('event', eventName, enhancedParams);

    // Debug logging in development
    if (process.env.NODE_ENV === 'development') {
      console.log('Analytics Event:', { eventName, params: enhancedParams });
    }
  }
};

// Add specific tracking functions for common events
export const trackFooterLinkClick = (linkType, destination) => {
  trackEvent('footer_link_click', {
    category: ANALYTICS_CONFIG.eventCategories.NAVIGATION,
    link_type: linkType,
    destination_url: destination,
    link_location: 'footer',
  });
};

export const trackCrossDomainNavigation = (destinationUrl) => {
  const sourceDomain = window.location.hostname;
  const destinationDomain = new URL(destinationUrl).hostname;

  trackEvent('cross_domain_navigation', {
    category: ANALYTICS_CONFIG.eventCategories.NAVIGATION,
    source_domain: sourceDomain,
    destination_domain: destinationDomain,
    user_journey: `${sourceDomain}_to_${destinationDomain}`,
    link_location: 'footer',
  });
};
