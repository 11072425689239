// src/data/dropdownData.js
export const medicalConditions = [
  'Hypertension (High Blood Pressure)',
  'Diabetes Mellitus (Type 1 and Type 2)',
  'Asthma',
  'Chronic Obstructive Pulmonary Disease (COPD)',
  'Heart Disease (e.g., Coronary Artery Disease, Congestive Heart Failure)',
  'Stroke',
  'Chronic Kidney Disease',
  'Liver Disease (e.g., Hepatitis, Cirrhosis)',
  'Cancer (e.g., Breast, Lung, Prostate)',
  'Arthritis (e.g., Osteoarthritis, Rheumatoid Arthritis)',
  'Osteoporosis',
  'Gastroesophageal Reflux Disease (GERD)',
  'Irritable Bowel Syndrome (IBS)',
  "Inflammatory Bowel Disease (e.g., Crohn's Disease, Ulcerative Colitis)",
  'Thyroid Disorders (e.g., Hypothyroidism, Hyperthyroidism)',
  'Anemia',
  'Migraine Headaches',
  'Epilepsy (Seizure Disorders)',
  'Depression',
  'Anxiety Disorders',
  'Bipolar Disorder',
  'Schizophrenia',
  'Autism Spectrum Disorder',
  'Chronic Pain',
  'Multiple Sclerosis (MS)',
  "Parkinson's Disease",
  "Alzheimer's Disease and Other Dementias",
  'Obesity',
  'Sleep Apnea',
  'HIV/AIDS',
  'Allergies (e.g., Seasonal Allergies, Food Allergies)',
  'Psoriasis',
  'Eczema (Atopic Dermatitis)',
  'Lupus (Systemic Lupus Erythematosus)',
  'Cystic Fibrosis',
];

export const dietaryHabits = [
  'Vegetarian',
  'Non-Vegetarian',
  'Lacto-Vegetarian',
  'Diabetic Diet',
  'Vegan',
  'Keto Diet',
  'Low-Carb Diet',
  'High-Protein Diet',
  'Low-Fat Diet',
];

export const exerciseFrequency = [
  'Daily',
  '3-4 times a week',
  '1-2 times a week',
  'Rarely',
  'Never',
];

export const smokingStatus = [
  'Non-Smoker',
  'Former Smoker',
  'Occasional Smoker',
  'Regular Smoker',
  'Heavy Smoker',
];

export const alcoholConsumption = [
  'Never',
  'Occasionally',
  'Social Drinker',
  'Regularly',
  'Heavy Drinker',
];

export const participationAvailability = [
  'Available Immediately',
  'Within 1 Month',
  'Within 3 Months',
  'Within 6 Months',
  'Not Available Currently',
];
