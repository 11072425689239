import { useClient } from '@vaidhyamegha/service-gateway-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const navigate = useNavigate();
  const client = useClient();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    if (!currentPassword || !newPassword || !confirmPassword) {
      setError('Please fill all the required fields.');
      setIsSubmitting(false);
      return;
    }

    if (newPassword === currentPassword) {
      setError('New password must be different from the current password.');
      setNewPassword('');
      setConfirmPassword('');
      setIsSubmitting(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setNewPassword('');
      setConfirmPassword('');
      setIsSubmitting(false);
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;

    if (!passwordRegex.test(newPassword)) {
      setError(
        'New password must be 8-25 characters and include an uppercase letter, lowercase letter, number, and special character.'
      );
      setIsSubmitting(false);
      return;
    }

    try {
      await client.changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully.');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => navigate('/dashboard'), 2000);
    } catch (err) {
      if (err?.message?.includes('401')) {
        setError(
          'The current password you entered is incorrect. Please try again.'
        );
      } else if (err?.message?.includes('429')) {
        setError('Too many attempts. Please try again later.');
      } else {
        setError('Failed to change password.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='flex justify-center sm:mt-32 mt-20 w-full mx-auto min-h-screen'>
      <div className='w-full max-w-lg mx-auto xl:p-0 sm:p-8 md:p-0'>
        {error && (
          <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
            <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
              {error}
            </p>
          </div>
        )}
        {success && (
          <div className='mb-4 p-4 bg-green-50 border border-green-200 rounded-md'>
            <p className="text-green-600 text-sm font-['Proxima Nova'] text-center">
              {success}
            </p>
          </div>
        )}
        <div className='bg-white shadow-md rounded-lg p-8 w-full max-w-xl border-2 border-blue-300 flex flex-col items-center'>
          <h2 className='text-2xl font-bold text-[#1876be] text-center mb-6'>
            Change Password
          </h2>
          <form onSubmit={handlePasswordChange} className='w-full space-y-4'>
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[15px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 sm text-[#2685ce] text-base font-medium'>
                Current Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[20px] pr-10 rounded-md shadow-inner border border-[#b3e9f4]"}`}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                  placeholder='Enter your current Password'
                />
                <button
                  type='button'
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showCurrentPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[15px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                New Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[20px] pr-10 rounded-md shadow-inner border 
                        ${
                          newPassword &&
                          confirmPassword &&
                          newPassword.length >= 8
                            ? newPassword === confirmPassword
                              ? 'border-green-500'
                              : 'border-red-500'
                            : 'border-[#b3e9f4]'
                        }`}
                  id='newPassword'
                  value={newPassword}
                  placeholder='Enter your New Password'
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[15px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Confirm Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={` w-full h-10 bg-white pl-[20px] pr-10 rounded-md shadow-inner border
                        ${
                          newPassword &&
                          confirmPassword &&
                          newPassword.length >= 8
                            ? newPassword === confirmPassword
                              ? 'border-green-500'
                              : 'border-red-500'
                            : 'border-[#b3e9f4]'
                        }`}
                  placeholder='Confirm your New Password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id='newPassword'
                  required
                />
                <button
                  type='button'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer  text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showConfirmPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div className='w-full flex justify-start sm:justify-center'>
              <button
                type='submit'
                disabled={isSubmitting}
                className='w-40 sm:ml-16 bg-[#1876be]  text-white font-semibold py-2 px-5 rounded-md hover:bg-blue-700 transition'
              >
                {isSubmitting ? 'Saving...' : 'SAVE CHANGES'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
