import { useInitializePayment } from '@vaidhyamegha/service-gateway-react';
import React, { useEffect, useState } from 'react';
import Button from '../../components/button';

const Billing = () => {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const initializePayment = useInitializePayment();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerContact, setCustomerContact] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
        );
        const data = await response.json();
        setCountries(data.countries.map((c) => c.label));
      } catch (err) {
        console.error('Failed to fetch countries:', err);
      }
    };
    fetchCountries();
  }, []);

  const validateForm = () => {
    setError('');
    if (
      !customerName.trim() ||
      !/^(?!No$)[A-Za-z\d](?:[A-Za-z\d ]{0,248}[A-Za-z\d])?$/.test(customerName)
    ) {
      return setError(
        "Full Name can only contain letters, numbers, and spaces (max 248 characters). 'No' is not allowed."
      );
    }

    if (
      !customerEmail.trim() ||
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(customerEmail)
    )
      return setError('Please enter a valid email address.');
    if (!customerContact.trim() || !/^\d{10}$/.test(customerContact))
      return setError('Please enter a valid 10-digit mobile number.');
    if (!address.trim()) return setError('Please enter your address.');
    if (!country.trim()) return setError('Please select your country.');
    return true;
  };

  const handlePayment = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    setPaymentStatus('Initializing payment...');
    await new Promise((resolve) => setTimeout(resolve, 0));
    const PaymentInitRequest = {
      callback_url: process.env.REACT_APP_CALLBACK_URL,
      Callback_method: 'get',
      customer: {
        name: customerName,
        email: customerEmail,
        contact: customerContact
      }
    };

    try {
      const paymentResponse = await initializePayment(PaymentInitRequest);
      setPaymentStatus(
        `Payment Initialized. Link: ${paymentResponse.payment_link}, Reference ID: ${paymentResponse.reference_id}`
      );

      setTimeout(() => {
        window.location.href = paymentResponse.payment_link;
      }, 500);
    } catch (error) {
      console.error('Payment initialization failed:', error);
      setPaymentStatus(
        'Payment initialization failed. Check console for details.'
      );
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <div className='flex justify-center w-full min-h-screen bg-white px-4 py-8'>
      <div className='w-full max-w-[1158px] mx-30'>
        <div className='text-center mb-6'>
          <h1 className="text-[#2685ce] text-2xl font-semibold font-['Proxima Nova']">
            Complete Your Subscription
          </h1>
          <p className="text-[#5b5b5b] text-base font-normal font-['Proxima Nova'] mt-1">
            Annual Subscription (1 Year)
          </p>
        </div>
        <h2 className="text-[#2685ce] text-xl font-semibold font-['Proxima Nova'] mb-2">
          Billing Information
        </h2>

        {/* Error Alert */}
        {error && (
          <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
            <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
              {error}
            </p>
          </div>
        )}

        {/* Payment Status (if you want to display after payment attempt)
        {paymentStatus && (
          <div className='mb-4 p-4 bg-blue-50 border border-blue-200 rounded-md'>
            <p className="text-blue-600 text-sm font-['Proxima Nova'] text-center">
              {paymentStatus}
            </p>
          </div>
        )} */}

        {/* Billing Information Card */}
        <div className='bg-[#f9fffe] rounded-[10px] shadow-md p-6 border border-[#d0ebff]'>
          {/* Full Name */}
          <div className='mb-4 flex flex-col md:flex-row md:items-center'>
            <label
              htmlFor='fullName'
              className="md:w-1/3 md:text-right md:pr-4 text-[#2685ce] text-base font-medium font-['Proxima Nova']"
            >
              Full Name<span className='text-[#e63a52]'>*</span>:
            </label>
            <input
              id='fullName'
              type='text'
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className='w-full md:w-2/3 h-10 bg-white rounded-md border max-w-md  border-[#b3e9f4] shadow-inner pl-3 mt-2 md:mt-0'
              placeholder='Enter your full name'
            />
          </div>

          {/* Email Address */}
          <div className='mb-4 flex flex-col md:flex-row md:items-center'>
            <label
              htmlFor='email'
              className="md:w-1/3 md:text-right md:pr-4 text-[#2685ce] text-base font-medium font-['Proxima Nova']"
            >
              Email Address<span className='text-[#e63a52]'>*</span>:
            </label>
            <input
              id='email'
              type='email'
              autocomplete='email'
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              className='w-full md:w-2/3 h-10 bg-white rounded-md max-w-md border border-[#b3e9f4] shadow-inner pl-3 mt-2 md:mt-0'
              placeholder='Enter your email'
            />
          </div>

          {/* Mobile Number */}
          <div className='mb-4 flex flex-col md:flex-row md:items-center'>
            <label
              htmlFor='mobileNumber'
              className="md:w-1/3 md:text-right md:pr-4 text-[#2685ce] text-base font-medium font-['Proxima Nova']"
            >
              Mobile Number<span className='text-[#e63a52]'>*</span>:
            </label>
            <input
              id='mobileNumber'
              type='text'
              value={customerContact}
              onChange={(e) => setCustomerContact(e.target.value)}
              className='w-full md:w-2/3 h-10 bg-white rounded-md max-w-md border border-[#b3e9f4] shadow-inner pl-3 mt-2 md:mt-0'
              placeholder='10-digit contact number'
            />
          </div>

          {/* Address */}
          <div className='mb-4 flex flex-col md:flex-row'>
            <label className="md:w-1/3 md:text-right md:pr-4 text-[#2685ce] text-base font-medium font-['Proxima Nova']">
              Address<span className='text-[#e63a52]'>*</span>:
            </label>
            <textarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className='w-full md:w-2/3 h-24 bg-white rounded-md border max-w-md border-[#b3e9f4] shadow-inner pl-3 pt-2 mt-2 md:mt-0 resize-none'
              placeholder='Enter your address'
            />
          </div>

          {/* Country */}
          <div className='mb-4 flex flex-col md:flex-row md:items-center'>
            <label className='md:w-1/3 md:text-right md:pr-4 text-[#2685ce] text-base font-medium'>
              Country<span className='text-[#e63a52]'>*</span>:
            </label>

            <div className='relative w-full md:w-2/3 h-10 mt-2 md:mt-0 max-w-md'>
              <select
                className='
        relative 
        z-0
        w-full 
        h-full 
        bg-white 
        border border-[#b3e9f4] 
        shadow-inner 
        rounded-md 
        pl-3 
        pr-10 
        appearance-none 
        outline-none
      '
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option
                  value=''
                  disabled
                >
                  Select
                </option>
                {countries.map((c, index) => (
                  <option
                    key={index}
                    value={c}
                  >
                    {c}
                  </option>
                ))}
              </select>

              {/* The arrow container sits absolutely on top (z-10) */}
              <div className='absolute top-1/2 right-2 transform -translate-y-1/2 z-10 pointer-events-none'>
                <img
                  src='subscription/dropdown-arrow.svg'
                  alt='Dropdown Arrow'
                  className='h-4 w-4'
                />
              </div>
            </div>
          </div>

          {/* Total Payable */}
          <div className='my-6 text-center border-t  border-[#81d2e9] pt-4'>
            <span className="text-[#2685ce] text-xl font-semibold font-['Proxima Nova']">
              Total Payable:{' '}
            </span>
            <span className="text-black text-xl font-semibold font-['Proxima Nova']">
              ₹99
            </span>
          </div>

          {/* Action Buttons */}
          <div className='flex flex-col sm:flex-row items-center justify-center pb-4 gap-4'>
            <Button
              onClick={handlePayment}
              disabled={isLoading}
              variant='csdlc_button'
            >
              <div className="flex i flex-coltems-center gap-2 text-white text-lg font-bold font-['Proxima Nova'] uppercase">
                {isLoading ? (
                  <>
                    <svg
                      className='animate-spin h-5 w-5'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                        fill='none'
                      />
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 
                          0 0 5.373 0 12h4zm2 5.291A7.962 
                          7.962 0 014 12H0c0 3.042 1.135 
                          5.824 3 7.938l3-2.647z'
                      />
                    </svg>
                  </>
                ) : (
                  'Proceed to Payment'
                )}
              </div>
            </Button>
          </div>

          {/* Cancel and Return */}
          <div className='flex flex-col sm:flex-row items-center justify-center '>
            <button
              onClick={() => (window.location.href = '/dashboard')}
              className='bg-transparent   hover:decoration-[#2685ce] hover:underline'
            >
              <span className="text-[#2685ce] text-base font-semibold font-['Proxima Nova']">
                Cancel and Return
              </span>
            </button>

            <span className="text-[#3e3e3e] text-base font-normal font-['Proxima Nova']">
              {'   '} &nbsp; to Dashboard!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
