import { useAuthState, useClient } from '@vaidhyamegha/service-gateway-react';
import React, { createContext, useCallback, useEffect, useState } from 'react';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const client = useClient();

  const authState = useAuthState();
  // Load user from localStorage if available
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // Function to fetch user details after login
  const fetchUserDetails = useCallback(async () => {
    try {
      if (!authState.isAuthenticated) return;

      const userInfo = client.getUser(); // Ensure this returns user data
      if (userInfo) {
        setUser(userInfo);
        localStorage.setItem('user', JSON.stringify(userInfo)); // Store user persistently
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }, [authState.isAuthenticated, client]);

  // Restore session on page load
  useEffect(() => {
    if (!user && authState.isAuthenticated) {
      fetchUserDetails();
    }
  }, [authState.isAuthenticated, fetchUserDetails, user]);
  const login = async (email, password, options) => {
    try {
      const response = await client.loginIndividual(email, password, options);

      return response;
    } catch (err) {
      throw new Error(err.message || 'Login failed');
    }
  };

  const logout = useCallback(() => {
    client.logout();
    setUser(null);
    localStorage.removeItem('user');
  }, [client]);

  const getLoadingState = async () => {
    try {
      return await client.getLoadingState();
    } catch (err) {
      throw new Error(err.message || 'Failed to get loading state');
    }
  };

  const register = async ({ fullName, email, password }) => {
    try {
      await client.registerIndividual({
        name: fullName,
        email,
        password,
      });
      await fetchUserDetails();
    } catch (err) {
      if (err?.message?.includes('409')) {
        throw new Error(
          'Email is already registered. Please use a different email or log in.'
        );
        // throw new Error(err.message || "Login failed");
      } else {
        throw new Error('Registration failed');
      }
    }
  };

  const value = { user, client, login, logout, register, getLoadingState };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
