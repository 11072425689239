// src/services/cookieService.js

export const setCookie = (name, value) => {
  const isSecure = window.location.protocol === 'https:'; // Check if using HTTPS
  document.cookie = `${name}=${encodeURIComponent(value)}; path=/; ${
    isSecure ? 'Secure;' : ''
  } ; SameSite=Strict;`;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return decodeURIComponent(parts.pop().split(';').shift());
  }
  return undefined;
};

export const removeCookie = (name) => {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
};
