import PropTypes from 'prop-types';
import React, { memo } from 'react';

const TermItem = memo(({ term, searchTerm, highlightText, index }) => {
  // Enable keyboard interaction for accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.target.click();
    }
  };

  return (
    <div
      role='article'
      tabIndex={0}
      aria-labelledby={`term-title-${term.id}`}
      onKeyDown={handleKeyDown}
      className='
        flex 
        flex-col 
        gap-2 
        p-6 
        border 
        border-[#80D6E9] 
        rounded-lg 
        bg-white 
        h-full 
        box-border 
        shadow-sm
      '
    >
      <h3
        id={`term-title-${term.id}`}
        tabIndex={0}
        className='
          text-xl 
          text-[#2685CE] 
          m-0 
          font-medium
          focus:bg-[rgba(38,133,206,0.1)]
          focus:rounded
          outline-none
        '
      >
        {highlightText(term.Term, searchTerm)}
      </h3>
      <p
        className='
          m-0 
          leading-[1.4] 
          text-[#333] 
          focus:bg-[rgba(38,133,206,0.1)]
          focus:rounded
          outline-none
        '
        tabIndex={0}
        aria-label={`Description for ${term.Term}`}
      >
        {highlightText(term.Description, searchTerm)}
      </p>
    </div>
  );
});

TermItem.propTypes = {
  term: PropTypes.shape({
    id: PropTypes.string.isRequired,
    Term: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
  }).isRequired,
  searchTerm: PropTypes.string,
  highlightText: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default TermItem;
