// src/App.js

import {
  ServiceGatewayProvider,
  useAuthState
} from '@vaidhyamegha/service-gateway-react';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import FrameComponent from './components/FrameComponent';
import Layout from './components/layout';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/scrollToTop';
import MainAuth from './components/switcher';
import { AuthProvider } from './context/AuthContext';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import ChangePassword from './pages/change-password/change-password';
import ResetPassword from './pages/change-password/reset-password';
import Dashboard from './pages/dashboard/dashboard';
import Dictionary from './pages/dictionary/Dictionary';
import ForgotPassword from './pages/forgot-password/forgot-password';
import Login from './pages/login/login';
import PrivacyPolicyPage from './pages/privacy-policy/privacy-policy';
import RefundPolicy from './pages/privacy-policy/refund';
import TermsAndConditions from './pages/privacy-policy/terms&conditions';
import ProfileUpdate from './pages/profile-update/profile-update';
import Register from './pages/registration/register';
import Reports from './pages/reports/reports';
import Billing from './pages/subscription/billing';
import SubscriptionLandingpage from './pages/subscription/SubscriptionLandingpage';
import ThankYouPage from './pages/thankyou/ThankYouPage';
import { initializeAnalytics } from './utils/analytics';

function AppRoutes() {
  const authState = useAuthState();

  React.useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route
          path='/newsletters/*'
          element={<StaticFileHandler />}
        />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path='/auth'
          element={<MainAuth />}
        >
          {/* <Route path="login" element={<Login />} /> */}
          <Route
            path='login'
            element={
              !authState.isAuthenticated ? (
                <Login />
              ) : (
                <Navigate
                  to='/dashboard'
                  replace
                />
              )
            }
          />
          <Route
            path='register'
            element={
              !authState.isAuthenticated ? (
                <Register />
              ) : (
                <Navigate
                  to='/dashboard'
                  replace
                />
              )
            }
          />
        </Route>
        {/* <Route path="/" element={<SubscriptionLandingpage replace />} /> */}
        <Route
          path='/'
          element={
            authState.isAuthenticated ? (
              <Navigate
                to='/dashboard'
                replace
              />
            ) : (
              <SubscriptionLandingpage />
            )
          }
        />

        <Route
          path='/thankyou'
          element={<ThankYouPage />}
        />
        <Route
          path='/profile-update'
          element={
            <PrivateRoute>
              <ProfileUpdate isReadOnly={false} />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile'
          element={
            <PrivateRoute>
              <ProfileUpdate isReadOnly={true} />
            </PrivateRoute>
          }
        />
        <Route
          path='/change-password'
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />
        <Route
          path='/reset-password'
          element={<ResetPassword />}
        />
        <Route
          path='/forgot-password'
          element={<ForgotPassword />}
        />
        <Route
          path='/reports'
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path='/billing'
          element={
            <PrivateRoute>
              <Billing />
            </PrivateRoute>
          }
        />
        <Route
          path='/dictionary'
          element={<Dictionary />}
        />

        {/* Add route for Privacy Policy */}
        <Route
          path='/privacy-policy'
          element={<PrivacyPolicyPage />}
        />
        <Route
          path='/terms-and-conditions'
          element={<TermsAndConditions />}
        />
        <Route
          path='/refund-policy'
          element={<RefundPolicy />}
        />

        {/* Add other routes here as needed */}
      </Routes>
    </Layout>
  );
}

function StaticFileHandler() {
  React.useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/newsletters/')) {
      window.location.href = path;
    }
  }, []);
  return null;
}

function App() {
  return (
    <ErrorBoundary>
      <ServiceGatewayProvider
        config={{
          baseUrl: 'https://gateway.fs.vaidhyamegha.com',
          apiVersion: 'v1'
        }}
      >
        <AuthProvider>
          <Router>
            <AppRoutes />
            <FrameComponent />
          </Router>
        </AuthProvider>
      </ServiceGatewayProvider>
    </ErrorBoundary>
  );
}

export default App;
