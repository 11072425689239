import {
  useAuthState,
  useCheckVerificationStatus,
} from '@vaidhyamegha/service-gateway-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button'; // Adjust the path as necessary
import { AuthContext } from '../../context/AuthContext';

const Login = () => {
  const authState = useAuthState();

  const { login, isLoginLoading } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(true);

  useEffect(() => {
    if (authState.isAuthenticated) {
      navigate('/dashboard');
    }
  }, [authState.isAuthenticated, navigate]);

  const checkVerificationStatus = useCheckVerificationStatus();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!trimmedEmail) {
      setError('Please enter your email.');
      setLoading(false);
      return;
    }
    if (!emailRegex.test(trimmedEmail)) {
      setError('Please enter a valid email.');
      setLoading(false);
      return;
    }

    if (!trimmedPassword) {
      setError('Please enter your password.');
      setLoading(false);
      return;
    }

    try {
      const startTime = Date.now();

      await login(trimmedEmail, trimmedPassword, { persist: keepMeLoggedIn });

      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(3000 - elapsedTime, 0);

      await new Promise((resolve) => setTimeout(resolve, remainingTime));
    } catch (err) {
      if (err?.message?.includes('401')) {
        setError('Invalid email or password.');
      } else if (err?.message?.includes('403')) {
        if (!email) return;

        const fetchVerificationStatus = async () => {
          try {
            const response = await checkVerificationStatus(email);
            setIsVerifiedEmail(response.isActive); // true if verified, false otherwise
          } catch (error) {
            console.error('Error checking verification status:', error);
            setIsVerifiedEmail(null);
          }
        };
        fetchVerificationStatus();
        setError('Your email is not verified.');
      } else if (err?.message?.includes('400')) {
        setError('Bad request.');
      } else if (err?.message?.includes('404')) {
        setError('User not found.');
      } else {
        setError('Login failed');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex justify-center w-full min-h-screen'>
      <div className='w-full max-w-xl mx-auto  xl:p-0 sm:p-8 md:p-0'>
        <div className='w-full'>
          {error && (
            <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
              <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
                {error}
              </p>
            </div>
          )}

          <form
            onSubmit={handleLogin}
            className='bg-[#f9fffe] rounded-[10px] shadow-md p-6 sm:p-8 md:p-10 border border-[#d0ebff] mx-auto'
          >
            {/* Email Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row item-center sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Email ID<span className='text-[#e63a52]'>*</span>:
              </label>
              <input
                type='text'
                className='
                    sm:w-3/4
   

                    h-10 bg-white pl-[10px] rounded-md shadow-inner border border-[#b3e9f4]'
                placeholder='Enter your Email ID'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoginLoading}
              />
            </div>

            {/* Password Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[41px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className=' sm:w-3/4'>
                <input
                  id='hs-toggle-password'
                  type={showPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[10px] pr-14 rounded-md shadow-inner border "border-[#b3e9f4]"`}
                  placeholder='Enter your Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute inset-y-0 right-2 sm:mt-0 mt-8 flex items-center  px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle cx='12' cy='12' r='3'></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line x1='2' x2='22' y1='2' y2='22'></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            <div
              className='mb-4 flex flex-col sm:ml-1.5
                sm:flex-row sm:justify-center sm:text-right 
 t'
            >
              <label
                className='w-2/4 pb-2

                  pr-2 text-[#2685ce] text-base font-medium'
              />
              <div
                className='mb-2 flex  
                        sm:w-3/4
    
                items-center'
              >
                <input
                  type='checkbox'
                  className='w-[18px] h-[18px] bg-[#f2fffe] rounded-sm shadow-inner border border-[#80d6e9]'
                  checked={keepMeLoggedIn}
                  onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
                />
                <label className="text-[#3e3e3e] text-sm ml-4 font-normal font-['Proxima Nova']">
                  Keep me logged in
                </label>
              </div>
            </div>
            <div
              className='mb-4 flex
                mb:flex-col mb:justify-center mb:text-left
                sm:flex-row sm:justify-center sm:text-right 
                md:flex-row md:justify-center md:text-right
                xl:flex-row xl:justify-center xl:text-right
                xxl:flex-row xxl:justify-center xxl:text-right
                sm:ml-1.5'
            >
              <label
                className='w-2/4 
                  mb:pb-0
                  sm:pb-0
                  md:pb-0
                  xl:pb-0
                  xxl:pb-0
                  lg:pb-0

                  pr-2 text-[#2685ce] text-base font-medium'
              />
              <div
                className='mb-2 flex text-right  
                           sm:w-3/4 
'
              >
                <Button
                  label='LOGIN'
                  type='submit'
                  disabled={loading || isLoginLoading}
                  variant='csdlc_button'
                >
                  {loading ? (
                    <svg
                      className='animate-spin h-5 w-5 mr-3'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      />
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z'
                      />
                    </svg>
                  ) : (
                    'LOGIN'
                  )}
                </Button>
              </div>
            </div>

            {/* Links */}

            <div
              className='mb-1 flex
                mb:flex-col mb:justify-center mb:text-left
                sm:flex-row sm:justify-center sm:text-right 
                md:flex-row md:justify-center md:text-right
                xl:flex-row xl:justify-center xl:text-right
                xxl:flex-row xxl:justify-center xxl:text-right
                sm:ml-1.5'
            >
              <label
                className='w-2/4 
                  mb:pb-2
                  sm:pb-0
                  md:pb-0
                  xl:pb-0
                  xxl:pb-0
                  lg:pb-0

                  pr-2 text-[#2685ce] text-base font-medium'
              />
              <div
                className='mb-0 flex 
                           xl:w-3/4
                    xxl:w-3/4
                    lg:w-3/4
                    sm:w-3/4
                    md:w-3/4
                    mb:w-full'
              >
                <div>
                  <span
                    className="text-[#3e3e3e]
                     sm:ml-[0px]
                     md:ml-[0px]
                      lg:ml-[px]
                      xl:ml-[0px]
                      xxl:ml-[0px]
                      mb:ml-[0px]
                      text-sm font-normal font-['Proxima Nova']"
                  >
                    Forgot Password?{' '}
                  </span>
                  <span className="text-[#2685ce] text-sm font-semibold font-['Proxima Nova'] underline">
                    <a
                      href='/forgot-password'
                      className='text-[#2685ce] underline'
                    >
                      Reset Now!
                    </a>
                  </span>
                </div>
              </div>
            </div>

            {!isVerifiedEmail && (
              <div
                className='mb-1 flex
                mb:flex-col mb:justify-center mb:text-left
                sm:flex-row sm:justify-center sm:text-left
                md:flex-row md:justify-center md:text-left
                xl:flex-row xl:justify-center xl:text-left
                xxl:flex-row xxl:justify-center xxl:text-left
                sm:ml-1.5'
              >
                <label
                  className='w-2/4 
                  mb:pb-2
                  sm:pb-0
                  md:pb-0
                  xl:pb-0
                  xxl:pb-0
                  lg:pb-0

                  pr-2 text-[#2685ce] text-base font-medium'
                />
                <div
                  className='mb-0 flex 
                           xl:w-3/4
                    xxl:w-3/4
                    lg:w-3/4
                    sm:w-3/4
                    md:w-3/4
                    mb:w-full'
                >
                  <div>
                    <span
                      className="text-[#3e3e3e]
                     sm:ml-[0px]
                     md:ml-[0px]
                      lg:ml-[px]
                      xl:ml-[0px]
                      xxl:ml-[0px]
                      mb:ml-[0px]
                      text-sm font-normal font-['Proxima Nova']"
                    >
                      Didn’t receive the verification Email?{' '}
                    </span>
                    <span className="text-[#2685ce] text-sm font-semibold font-['Proxima Nova'] underline">
                      <a
                        href='https://verify.vaidhyamegha.com/#/request-verification
'
                        className='text-[#2685ce] underline'
                      >
                        Resend it!
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              className='mb-1 flex
                mb:flex-col mb:justify-center mb:text-left
                sm:flex-row sm:justify-center sm:text-right 
                md:flex-row md:justify-center md:text-right
                xl:flex-row xl:justify-center xl:text-right
                xxl:flex-row xxl:justify-center xxl:text-right'
            >
              <label
                className='w-2/4 
                  mb:pb-2
                  sm:pb-0
                  md:pb-0
                  xl:pb-0
                  xxl:pb-0
                  lg:pb-0

                  pr-2 text-[#2685ce] text-base font-medium'
              />
              <div
                className='mb-2 flex
                           xl:w-3/4
                    xxl:w-3/4
                    lg:w-3/4
                    sm:w-3/4
                    md:w-3/4
                    mb:w-full
                    sm:ml-1.5'
              >
                <span
                  className="text-[#3e3e3e] 
                    sm:ml-[0px] 
                    md:ml-[0px]
                    lg:ml-[0px]
                    xl:ml-[0px]
                    xxl:ml-[0x]
                    mb:ml-[0px]
                

                    text-sm font-normal font-['Proxima Nova']"
                >
                  New to the platform?{' '}
                </span>
                <span className="text-[#2685ce] text-sm font-semibold font-['Proxima Nova'] underline">
                  <a href='/auth/register' className='text-[#2685ce] underline'>
                    Register Now!
                  </a>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
