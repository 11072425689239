import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="relative font-['Proxima Nova'] justify-center items-center overflow-hidden bg-[var(--bk6)] mb-16">
      {/* Banner Section */}
      <div className="self-stretch flex flex-col sm:flex-row text-left sm:h-[500px] h-[500px]  text-[#30d4be] font-['Proxima Nova'] pb-8 sm:pb-[var(--padding-36xl)] relative">
        {/* Desktop Image (Visible only on Desktop) */}
        <img
          className='hidden sm:block absolute inset-0 h-full w-full object-cover'
          loading='lazy'
          alt='privacy policy banner'
          src='/privacy_policy/t&c.png'
        />

        <img
          className='sm:hidden w-full h-full object-cover'
          loading='lazy'
          alt='privacy policy mobile banner'
          src='/privacy_policy/mobile_tc.png'
        />

        <div className='absolute inset-0 flex flex-col items-center justify-start pt-10 sm:pt-[155px] text-center sm:items-start sm:ml-[100px] sm:text-left sm:p-0 p-4 sm:mx-0 mx-2 z-10'>
          {/* Button */}
          <button className='bg-[#30d4be] border-0 rounded-[40px] px-2 sm:px-0 py-3 pointer-events-none  text-white font-[var(--font-proxima-nova)]  text-base w-[90%] sm:w-[431px]'>
            Terms and Conditions
          </button>

          {/* Privacy Policy Title */}
          <h1 className='mt-4 text-xl sm:text-[40px] font-semibold pt-8 leading-tight'>
            Terms and Conditions
            <br />
            for VaidhyaMegha Private Limited
          </h1>
        </div>
      </div>

      <section className='flex justify-center sm:text-justify w-full mt-[10px]  p-0'>
        <div className="w-full mx-[20px] sm:mx-[100px] font-['Proxima Nova'] space-y-2 p-2 sm:p-0">
          <div className='bg-white overflow-hidden max-w-screen-3xl  '>
            <div className='py-10'>
              <h1 className='text-[#002169] text-2xl font-bold'>
                Terms and Conditions
              </h1>
              <p className='text-gray-600 text-sm mt-2'>
                Last Updated: December 24, 2024
              </p>
            </div>

            <div className='space-y-4'>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  Agreement to Legal Terms
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  These Terms and Conditions ("Terms") govern your access to and
                  use of the services offered by VaidhyaMegha Private Limited
                  ("Company," "we," "us," "our"), a company registered in India
                  at the address: 3-5-139/3/3/2, Shiva Nagar Colony, Attapur,
                  Hyderguda, Hyderabad, Telangana 500048, India.
                </p>
                <p className='text-gray-600 text-base mt-2'>
                  In addition to our registered office, we also maintain a
                  presence at the following locations: Vaidhyamegha Pvt. Ltd:
                  T-Hub, 7th Floor, AI & ML, 20, Inorbit Mall Rd, Vittal Rao
                  Nagar, Madhapur, Hyderabad, Telangana 500081 India.
                  VaidhyaMegha LLC: Virtual office at Spencer Schneier MBR, 30 N
                  Gould St, STE R, Sheridan, WY 82801, USA.
                  <p className='mt-0 mb-5'>
                    Please note that while the USA location serves as a virtual
                    office,
                  </p>
                  By using our website,{' '}
                  <a
                    href='http://www.vaidhyamegha.com'
                    className='text-blue-600 underline'
                  >
                    http://www.vaidhyamegha.com
                  </a>{' '}
                  ("Site"), and any related products, applications, or services
                  (collectively, "Services"), you confirm that you have read,
                  understood, and agreed to th...
                  <p className='mt-0 mb-5' />
                  You may contact us at: <br />
                  - Phone: +91 9618986039
                  <br />- Email: contact@vaidhyamegha.com
                  <br />- Address: 3-5-139/3/3/2, Shiva Nagar Colony, Attapur,
                  Hyderguda, Hyderabad, Telangana 500048, India. <br />
                  <p className='mt-0 mb-5' />
                  These Terms are legally binding and apply to all users,
                  whether using the Services on behalf of a business or
                  individually. If we revise these Terms, we will provide notice
                  of such changes via email or on our website at least 15 days
                  prior to the effective date of the revised Terms. Continued
                  use of our Services following such changes indicates
                  acceptance of the updated Terms.
                </p>
              </section>

              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  Table of Contents
                </h2>
                <div className='grid md:grid-cols-2 gap-6 text-gray-600 text-base font-bold mt-4'>
                  <div>
                    <p>1. Our Services</p>
                    <p>2. Intellectual Property Rights</p>
                    <p>3. User Eligibility and Representations</p>
                    <p>4. Account Registration</p>
                    <p>5. Purchases and Payment Terms</p>
                    <p>6. Subscriptions and Automatic Renewals</p>
                    <p>7. Restricted and Prohibited Activities</p>
                    <p>8. User-Generated Content</p>
                    <p>9. License to Your Content</p>
                    <p>10. Links to Third-Party Sites and Content</p>
                  </div>
                  <div>
                    <p>11. Privacy Policy</p>
                    <p>12. Term, Termination, and Suspension</p>
                    <p>13. Disclaimers</p>
                    <p>14. Limitation of Liability</p>
                    <p>15. Indemnification</p>
                    <p>16. User Data Responsibility</p>
                    <p>17. Electronic Communications</p>
                    <p>18. Governing Law and Dispute Resolution</p>
                    <p>19. Entire Agreement and Severability</p>
                    <p>20. Contact Information</p>
                  </div>
                </div>
              </section>

              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  1. Our Services
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Our Services include healthcare data analytics, cloud-based
                  patient management solutions, and other related offerings,
                  providing value-added data solutions and analytics for users
                  within the healthcare industry. Our Services are designed
                  solely for authorized and lawful use, and any use contrary to
                  applicable laws, including but not limited to HIPAA, GDPR, or
                  FISMA standards, is strictly prohibited.
                </p>
                <p className='text-gray-600 text-base mt-2'>
                  International Access: Users accessing our Services from
                  outside India are responsible for compliance with local laws.
                  We disclaim any liability for failure to comply with laws
                  applicable in jurisdictions other than India. Access: Users
                  accessing our Services from outside India are responsible for
                  compliance with local laws. We disclaim any liability for
                  failure to comply with laws applicable in jurisdictions other
                </p>
              </section>

              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  2. Intellectual Property Rights
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Ownership: VaidhyaMegha Private Limited retains all rights,
                  title, and interest in our Services, including source code,
                  trademarks, text, design, and all proprietary elements
                  (collectively "Content" and "Marks"). Unauthorized copying,
                  distribution, or reproduction of any part of the Content or
                  Marks is strictly prohibited. Any breach may result in
                  immediate suspension of your access, legal action under
                  applicable laws, or both, including claims for
                  damages.Disputes arising from intellectual property violations
                  will be governed by the laws of India, with exclusive
                  jurisdiction in Hyderabad courts.
                </p>
                <p className='text-gray-600 text-base mt-2'>
                  Limited License: We grant you a limited, non-transferable,
                  non-sublicensable, revocable license to use our Services
                  solely for personal, non-commercial purposes. You agree not to
                  resell, redistribute, or otherwise use our intellectual
                  property without our express permission.
                </p>
                <p className='text-gray-600 text-base mt-2'>
                  {' '}
                  Breach and Legal Recourse: Unauthorized use of our Content or
                  Marks may result in account termination, immediate cessation
                  of your rights, and legal action for damages or injunctive
                  relief.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  3. User Eligibility and Representations
                </h2>
                <p className='text-gray-600 text-base mt-4'>
                  By using our Services, you agree and confirm that:
                  <br />
                  <ul className='decimal ml-6'>
                    <li>
                      1. You are legally capable of entering binding agreements
                      and are at least 18 years old.{' '}
                    </li>
                    <li>
                      2. You provide accurate, complete, and current information
                      for all aspects of registration.
                    </li>
                    3. You will not use our Services for unlawful activities or
                    misuse. <br />
                  </ul>
                  <p className='mt-2'>
                    Violation of these eligibility requirements may result in
                    suspension, legal action, or removal of access to the
                    Services.
                  </p>
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  4. Account Registration
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  To access and use certain features, you are required to
                  register an account. You agree to:{' '}
                  <ol className='list-decimal ml-6'>
                    {' '}
                    <li>
                      Maintain the confidentiality of your password and account
                      information.
                    </li>
                    <li>
                      {' '}
                      Be responsible for activities conducted under your
                      account.{' '}
                    </li>
                    <li>
                      Inform us immediately of any unauthorized use of your
                      account.
                    </li>
                  </ol>
                  <p className='mt-2'>
                    We reserve the right to terminate or reclaim usernames that
                    violate our policies or are deemed inappropriate.
                  </p>
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  5. Purchases and Payment Terms
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Accepted Payment Methods: We accept payments via Razorpay
                  payment gateway. <br />
                  Pricing: You agree to pay all fees and taxes associated with
                  your purchase of Services. We may adjust prices or correct
                  errors, even after payment has been received.
                  <br />
                  <br /> Refund Policy: Unless otherwise specified by law, all
                  purchases are non-refundable.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  6. Subscriptions and Automatic Renewals
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  <span className='font-bold text-[#4e4e4e]'>
                    Subscriptions:
                  </span>{' '}
                  Certain Services require subscriptions which renew
                  automatically. By signing up, you consent to these terms.
                  <br />
                  <br />
                  <span className='font-bold text-[#4e4e4e]'>
                    Automatic Renewal:
                  </span>{' '}
                  Subscription fees will renew using your selected payment
                  method unless canceled. Fees are subject to change, and we
                  will notify you in advance.
                  <br />
                  <br />
                  <span className='font-bold text-[#4e4e4e]'>
                    Cancellation:{' '}
                  </span>
                  To cancel, please do so in your account settings. Refunds are
                  not provided for cancellations that take effect after the
                  billing cycle.
                </p>
              </section>

              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  7. Restricted and Prohibited Activities
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  You agree to refrain from: <br />
                  <ol className='list-decimal ml-6'>
                    <li>
                      Circumventing security or breaching access controls.
                    </li>{' '}
                    <li>
                      Using bots, scripts, or any automation not authorized by
                      us.
                    </li>{' '}
                    <li>
                      Conducting any activity that could harm other users, the
                      Company, or any external parties.
                    </li>
                  </ol>
                  Breaching these restrictions may lead to suspension, legal
                  action, or reporting to law enforcement.
                </p>
              </section>

              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  8. User-Generated Content
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Our Services may allow users to post or share content. By
                  submitting content, you:
                  <ol className='list-decimal ml-6'>
                    <li>
                      Confirm ownership and responsibility for content accuracy.
                    </li>
                    <li>
                      Grant us a license to use, reproduce, and distribute your
                      content.
                    </li>
                  </ol>{' '}
                  Content that violates our Terms or is illegal may be removed.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  9. License to Your Content
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Feedback and Submissions: Any feedback, ideas, or suggestions
                  you submit to us can be used without compensation. You waive
                  any rights to claims or ownership for content provided to
                  improve our Services.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  10. Links to Third-Party Sites and Content
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Our Services may link to third-party sites. We are not liable
                  for third-party content, privacy practices, or external
                  transactions. We advise you to review their policies before
                  use.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  11. Privacy Policy
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Your data privacy is governed by our Privacy Policy, which is
                  accessible on our Site. By using our Services, you consent to
                  data handling as outlined in our policy.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  12. Term, Termination, and Suspension
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  These Terms remain effective until terminated by either party.
                  We reserve the right to terminate your account at our
                  discretion for any violation of these Terms or other lawful
                  reasons. Upon termination, your rights under these Terms will
                  cease.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  13. Disclaimers
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  The Services are provided "AS-IS" without warranties of any
                  kind. We disclaim all warranties, including merchantability,
                  fitness for a particular purpose, and non-infringement.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  14. Limitation of Liability
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  We are not liable for any indirect, incidental, consequential,
                  or special damages arising from the use of our Services. Our
                  liability is limited to the amount you paid us in the
                  preceding six months.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  15. Indemnification
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  You agree to indemnify, defend, and hold harmless VaidhyaMegha
                  Private Limited from claims, damages, and liabilities arising
                  from your use of the Services or violation of these Terms.
                </p>{' '}
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  16. User Data Responsibility
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  Although we strive to maintain data security, you are
                  responsible for data backups. We are not liable for data loss
                  or corruption.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  17. Electronic Communications
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  You consent to electronic communications and agree to the
                  electronic processing of records in relation to our Services.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  18. Governing Law and Dispute Resolution
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  These Terms are governed by the laws of India. Any disputes
                  arising shall be exclusively resolved in the courts located in
                  Hyderabad, India.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  19. Entire Agreement and Severability
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  These Terms represent the complete agreement between you and
                  VaidhyaMegha Private Limited. If any provision is deemed
                  invalid, the remaining provisions will remain enforceable.
                </p>
              </section>
              <section>
                <h2 className='text-[#002169] text-xl font-bold'>
                  20. Contact Information
                </h2>
                <p className='text-gray-600 text-base mt-2'>
                  For any questions regarding these Terms, please contact us at:{' '}
                  <br />
                  VaidhyaMegha Private Limited, 3-5-139/3/3/2, Shiva Nagar
                  Colony, Attapur, Hyderguda, Hyderabad, Telangana 500048,
                  India. <br />
                  Phone: +91 9618986039, Email: contact@vaidhyamegha.com.
                </p>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
