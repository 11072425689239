import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="relative font-['Proxima Nova'] overflow-hidden bg-[var(--bk6)]">
      {/* Banner Section */}
      <div className='relative w-full flex flex-col sm:flex-row text-left h-[550px] sm:h-[600px] pb-3 sm:pb-36'>
        {/* Desktop Image (visible on screens sm and up) */}
        <img
          className='hidden sm:block absolute inset-0 h-full w-full object-fill'
          loading='lazy'
          alt='privacy policy banner'
          src='/privacy_policy/rc-policy.png'
        />
        {/* Mobile Image (visible on screens smaller than sm) */}
        <img
          className='sm:hidden absolute inset-0 h-full w-full object-cover'
          loading='lazy'
          alt='privacy policy mobile banner'
          src='/privacy_policy/mobile_cnr_.png'
        />
        {/* Overlay Content */}
        <div className='absolute inset-0 flex flex-col items-center justify-start pt-20 sm:pt-[155px] text-center sm:items-start sm:ml-[100px] sm:text-left p-[0px]  z-10'>
          {/* Button */}
          <button className='bg-[#ffc65b] border-0 rounded-[40px] py-3 px-4  pointer-events-none  text-[#00558f] text-base font-semibold w-[90%] sm:w-[431px]'>
            Understanding Our Refund & Cancellation Policy
          </button>
          {/* Privacy Policy Title */}
          <h1 className='mt-4 text-xl sm:text-[40px] text-[#00558f] font-normal pt-8 leading-tight'>
            Refund and Cancellation Policy
            <br />
            for VaidhyaMegha Private Limited
          </h1>
        </div>
      </div>

      {/* Main Content Section */}

      <section className='flex justify-center sm:text-justify w-full p-0'>
        <div className="w-full mx-[20px] sm:mx-[100px] font-['Proxima Nova'] space-y-2 p-2 sm:p-0">
          <div className='bg-white overflow-hidden max-w-screen-3xl  '>
            <div className='py-10'>
              <h1 className='text-[#002169] text-2xl font-bold'>
                Refund and Cancellation Policy
              </h1>
              <p className='text-gray-600 text-sm mt-2'>
                Last Updated: December 24, 2024
              </p>
            </div>
          </div>
          <div className='space-y-4'>
            <section>
              <h2 className='text-[#002169] text-xl font-bold'>Introduction</h2>

              <p className='text-gray-700 pt-2'>
                Thank you for choosing the services and products offered by
                Vaidhyamegha Private Limited, accessible from &nbsp;
                <a
                  href='http://www.vaidhyamegha.com'
                  className='text-blue-600 underline'
                >
                  http://www.vaidhyamegha.com.
                </a>{' '}
                We are committed to delivering high-quality services and
                products and ensuring the satisfaction of our customers. This
                document outlines our policies for cancellations and refunds,
                Please read this policy carefully to understand your rights and
                obligations.
              </p>
            </section>
            <section>
              <h2 className='text-[#002169] text-xl font-bold'>
                Cancellation Policy
              </h2>

              <p className='text-gray-700 pt-2'>
                <span className='font-bold text-[#4e4e4e]'>
                  1.1 Subscription-Based Services
                </span>{' '}
              </p>
              <p className='text-gray-700 pt-2'>
                You may cancel your subscription-based services at any time.
                Following cancellation, you will retain access to the service
                until the end of the current paid billing period. No subsequent
                charges will be billed. To cancel your subscription, please use
                the options available in your account settings or contact our
                customer support team for guidance
              </p>
              <p className='text-gray-700 pt-2'>
                <span className='font-bold text-[#4e4e4e]'>
                  1.2 Perpetual License Products
                </span>{' '}
              </p>
              <p className='text-gray-700 pt-2'>
                Products purchased under a perpetual license grant ongoing
                access and are not subject to cancellation once activated.
                However, under certain circumstances outlined below, refunds may
                still be possible. If a refund is granted, access to the product
                will be deactivated immediately upon the cancellation of the
                license.
              </p>
            </section>
            <section>
              <h2 className='text-[#002169] text-xl font-bold'>
                Refund Policy
              </h2>

              <p className='text-gray-700 pt-2'>
                <span className='font-bold text-[#4e4e4e]'>
                  2.1 Eligibility for Refund
                </span>{' '}
              </p>
              <p className='text-gray-700 pt-2'>
                Refunds may be considered under the following conditions:
              </p>

              <ol className='list-decimal list-inside pt-2'>
                <li className='text-gray-700 pl-2'>
                  Technical Issues: If you encounter significant technical
                  problems or compatibility issues that prevent the normal
                  functioning of our product or service, provided that our
                  support team has had a reasonable opportunity to resolve these
                  issues without success
                </li>
                <li className='text-gray-700 pl-2'>
                  Dissatisfaction: If you are dissatisfied with the
                  functionality or performance of our product or service and
                  have provided specific feedback to our support team
                </li>

                <li className='text-gray-700 pl-2'>
                  Unauthorized Charges: If you notice unauthorized transactions
                  on your account resulting from purchases made through our
                  website
                </li>
              </ol>
              <p className='text-gray-700 pt-2'>
                <span className='font-bold text-[#4e4e4e]'>
                  2.2 Refund Process{' '}
                </span>{' '}
              </p>
              <p className='text-gray-700 pt-2'>
                Please follow these steps to request a refund:
              </p>

              <ol className='list-decimal list-inside pt-2'>
                <li className='text-gray-700 pl-2'>
                  Contact Customer Support: Submit a detailed refund request to
                  our support team, including your order number, purchase date,
                  and the specific reasons for the refund request
                </li>
                <li className='text-gray-700 pl-2'>
                  Evaluation: Our support team will review your request based on
                  the eligibility criteria mentioned above.
                </li>
              </ol>
              <p className='pt-2'>
                Refund Issuance: Approved refunds will be processed, and a
                credit will automatically be applied to your credit card or
                original method of payment, within a certain amount of days
                (typically 7 to 14 business days).
              </p>
              <p className='text-gray-700 pt-2'>
                <span className='font-bold text-[#4e4e4e]'>2.3 Exclusion</span>{' '}
              </p>
              <p className='text-gray-700 pt-2'>
                The following situations are not eligible for refunds:
              </p>

              <ol className='list-decimal list-inside pt-2'>
                <li className='text-gray-700 pl-2'>
                  Change of Mind: We do not grant refunds if you change your
                  mind about using our software or services but the product is
                  performing as advertised
                </li>
                <li className='text-gray-700 pl-2'>
                  Third-Party Products/Services: Refunds for third-party
                  products or services purchased through our platform are not
                  offered. Please contact the respective third party directly
                  for their refund policies
                </li>
              </ol>
            </section>
            <section>
              <h2 className='text-[#002169] text-xl font-bold'>
                Changes to Refund Policy
              </h2>
              <p className='text-gray-700 pt-2'>
                We reserve the right to update or modify this refund policy at
                any time without prior notice. Please review it periodically.
                Your continued use of our products and services after any
                changes signifies your acceptance of these changes.
              </p>
            </section>
            <section>
              <h2 className='text-[#002169] text-xl font-bold'>
                Contact Information
              </h2>

              <div className='pt-2 pb-8'>
                <span class="text-[#4e4e4e] text-base font-normal font-['Proxima Nova'] leading-snug">
                  For any questions or concerns regarding this policy, or to
                  initiate a refund or cancellation, please contact our customer
                  support team at:
                  <br />
                </span>
                <span class="text-[#4e4e4e] text-base font-normal font-['Proxima Nova'] leading-snug">
                  Email:{' '}
                </span>
                <span class="text-[#4299d4] text-base font-normal font-['Proxima Nova'] underline leading-snug">
                  contact@vaidhyamegha.com
                </span>
                <span class="text-[#4299d4] text-base font-normal font-['Proxima Nova'] leading-snug"></span>
                <br />
                <span class="text-[#4e4e4e] text-base font-normal font-['Proxima Nova'] leading-snug">
                  Address:3-5-139/3/3/2, Shiva Nagar Colony, Attapur, Hyderguda,
                  Hyderabad, Telangana 500048, India.
                </span>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RefundPolicy;
