import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className=' flex flex-col items-start justify-start w-full relative overflow-visible bg-[var(--bk6)]'>
      {/* Banner Section */}
      <div className="self-stretch flex flex-col sm:flex-row text-left sm:h-[500px] h-[500px]  text-[#0a226b] font-['Proxima Nova']  pb-8 sm:pb-[var(--padding-36xl)] relative">
        {/* Desktop Image (Visible only on Desktop) */}
        <img
          className='hidden sm:block absolute inset-0 h-full w-full object-cover'
          loading='lazy'
          alt='privacy policy banner'
          src='/privacy_policy/privacy-policy-header.png'
        />

        <img
          className='sm:hidden w-full h-full object-cover'
          loading='lazy'
          alt='privacy policy mobile banner'
          src='/privacy_policy/mobile_pp_banner.png'
        />

        <div className='absolute inset-0 flex flex-col items-center justify-start pt-10 sm:pt-[155px] text-center sm:items-start sm:ml-[85px] sm:text-left p-[15px]'>
          {/* Button */}
          <button className='bg-[#ffc65b] border-0 rounded-[40px] pointer-events-none py-3 text-[#0a226b] font-[var(--font-proxima-nova)]  text-base w-[90%] sm:w-[431px]'>
            Our Commitment to Transparency and Security
          </button>

          {/* Privacy Policy Title */}
          <h1 className='mt-4 text-xl sm:text-[40px] font-semibold pt-8 leading-tight'>
            Privacy Policy
            <br />
            for VaidhyaMegha Private Limited
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <section className='flex justify-center w-full mt-[10px] pb-10'>
        <div className='w-full mx-[10px] sm:mx-[100px] space-y-1 p-2 sm:p-0'>
          <h2 className='text-2xl font-bold text-[#002169]'>Privacy Policy</h2>
          <p className='text-gray-700 pt-4'>
            <strong>Last Updated:</strong> December 24, 2024
          </p>

          <p className='text-gray-700 pt-2'>
            VaidhyaMegha Private Limited ("Company," "we," "us," or "our") is
            committed to protecting your personal information and your right to
            privacy. This Privacy Policy describes how we collect, use, store,
            and disclose information about you when you access or use our
            website{' '}
            <a
              href='http://www.vaidhyamegha.com'
              className='text-blue-600 underline'
            >
              http://www.vaidhyamegha.com
            </a>{' '}
            (the "Site") and related services (collectively, "Services"). By
            using our Services, you consent to the practices outlined in this
            Privacy Policy, applicable to users in India, the United States, and
            other jurisdictions where our Services are accessed.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-2 mb-6'>
            Contact Information
          </h3>
          <p className='text-gray-700'>
            For any questions or concerns regarding this Privacy Policy, or to
            exercise your privacy rights, please contact us at:
          </p>
          <ul className='list-disc pl-6 text-gray-700'>
            <li>
              <strong>Email:</strong> contact@vaidhyamegha.com
            </li>
            <li>
              <strong>Phone:</strong> +91 9618986039
            </li>
            <li>
              <strong>Address:</strong>
              <ul className='list-disc pl-6'>
                <li>
                  Spencer Schneier MBR 30 N, Gould St STE R, Sheridan, WY 82801,
                  USA.
                </li>
                <li>
                  T-hub, 7th Floor, AI & ML20, Inorbit Mall Rd, Vittal Rao
                  Nagar, Madhapur, Hyderabad, Telangana 500081, India.
                </li>
                <li>
                  3-5-139/3/3/2, Shiva Nagar Colony, Attapur, Hyderguda,
                  Hyderabad, Telangana 500048, India.
                </li>
              </ul>
            </li>
          </ul>

          <h3 className='text-xl font-semibold text-[#002169] pt-4'>
            Table of Contents
          </h3>
          <ol className='list-decimal pl-6 text-gray-700'>
            <li>Information We Collect</li>
            <li>How We Use Your Information</li>
            <li>
              Legal Bases for Processing (Applicable to Users in India and US)
            </li>
            <li>Sharing and Disclosure of Information</li>
            <li>Data Retention</li>
            <li>Security of Your Information</li>
            <li>Cookies and Tracking Technologies</li>
            <li>Your Privacy Rights (India & US Specific)</li>
            <li>Third-Party Websites and Services</li>
            <li>Policy Updates</li>
            <li>Children's Privacy</li>
            <li>International Data Transfers</li>
            <li>Additional Notices for California Residents</li>
            <li>Contact Us</li>
          </ol>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            1. Information We Collect
          </h3>
          <p className='text-gray-700'>
            We collect various types of information to provide and improve our
            Services:
          </p>
          <ul className='list-disc pl-6 text-gray-700'>
            <li>
              <strong>Personal Information:</strong> This includes information
              such as your name, email address, contact number, billing
              information, and other identifiers provided during registration or
              transactions.
            </li>
            <li>
              <strong>Usage Data:</strong> Automatically collected information
              about how you interact with our Services, including IP address,
              browser type, and pages viewed, which helps us analyze and enhance
              user experience.
            </li>
            <li>
              <strong>Cookies and Tracking Data:</strong> Small data files
              stored on your device used to remember preferences and for
              analytics.
            </li>
          </ul>
          <p>
            Data collection practices are carried out with consent as required
            by law, and limited to the minimum information necessary to fulfill
            specified purposes.
          </p>
          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            2. How We Use Your Information
          </h3>
          <p className='text-gray-700'>
            We use your information for the following purposes:
          </p>
          <ol className='list-decimal pl-6 text-gray-700'>
            <li>To provide, operate, and maintain our Services.</li>
            <li>
              To communicate with you regarding updates, offers, and customer
              support.
            </li>
            <li>To process payments and fulfill transactions.</li>
            <li>To conduct research and analysis to improve our Services.</li>
            <li>
              To enforce our Terms, comply with applicable laws, and protect our
              legal rights.
            </li>
          </ol>
          <p>
            We only use your information for lawful purposes and disclose
            information only when necessary to provide services or comply with
            legal requirements.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            3. Legal Bases for Processing (Applicable to Users in India and US)
          </h3>
          <p>
            For users in India and the United States, we process your personal
            information based on the following legal grounds:
          </p>
          <ol>
            <li>
              <strong>Consent:</strong> Where you have given explicit consent
              for processing (e.g., for marketing communications).
            </li>
            <li>
              <strong>Contractual Necessity:</strong> To fulfill contractual
              obligations when you use our Services.
            </li>
            <li>
              <strong>Legal Compliance:</strong> To comply with laws and
              regulations applicable in India and the United States.
            </li>
            <li>
              <strong>Legitimate Interests:</strong> For purposes such as
              improving our Services, fraud prevention, and business continuity.
            </li>
          </ol>
          <p>
            We ensure transparency and respect your rights to withdraw consent
            where applicable.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            4. Sharing and Disclosure of Information
          </h3>
          <p className='text-gray-700'>
            We may share your information with third parties in the following
            scenarios:
          </p>
          <ul className='list-disc pl-6 text-gray-700'>
            <li>
              <strong>Service Providers:</strong> Third-party vendors who assist
              us in providing Services, including payment processors, hosting
              providers, and analytics services.
            </li>
            <li>
              <strong>Legal Obligations:</strong> In response to legal processes
              or to comply with applicable laws and regulatory requirements.
            </li>
            <li>
              <strong>Business Transfers:</strong> In cases of mergers,
              acquisitions, or asset sales, your data may be transferred to the
              acquiring entity.
            </li>
          </ul>
          <p className='text-gray-700'>
            Third parties are contractually required to maintain confidentiality
            and only use data as directed by us.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            5. Data Retention
          </h3>
          <p className='text-gray-700'>
            We retain personal data only as long as necessary to fulfill the
            purposes outlined in this Privacy Policy. Retention periods are
            determined based on business needs, legal requirements, and industry
            standards. Once data is no longer required, it is securely deleted
            or anonymized.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            6. Security of Your Information
          </h3>
          <p className='text-gray-700'>
            We implement industry-standard security measures to protect your
            data from unauthorized access and disclosure. Despite our efforts,
            no security measure is completely infallible. Users are encouraged
            to use strong passwords and maintain their account security to
            further protect their data.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            7. Cookies and Tracking Technologies
          </h3>
          <p className='text-gray-700'>
            We use cookies and similar tracking technologies to collect usage
            data, remember user preferences, and enhance your experience on our
            Site. You may choose to disable cookies via your browser settings,
            though this may affect certain features of the Services.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            8. Your Privacy Rights (India & US Specific)
          </h3>
          <p className='text-gray-700'>
            Depending on your location, you may have specific rights related to
            your personal data:
          </p>

          <h4 className='text-lg font-semibold text-[#002169]'>
            For Users in India:
          </h4>
          <ul className='list-disc pl-6 text-gray-700'>
            <li>
              <strong>Access and Correction:</strong> Request access to or
              correction of your personal data.
            </li>
            <li>
              <strong>Deletion:</strong> Request the deletion of your data under
              certain circumstances.
            </li>
            <li>
              <strong>Data Portability:</strong> Request a copy of your data in
              a portable format.
            </li>
          </ul>

          <h4 className='text-lg font-semibold text-[#002169]'>
            For Users in the United States:
          </h4>
          <ul className='list-disc pl-6 text-gray-700'>
            <li>
              <strong>Access:</strong> Request access to specific pieces of
              information collected.
            </li>
            <li>
              <strong>Deletion:</strong> Request deletion of personal
              information.
            </li>
            <li>
              <strong>Opt-Out of Sale:</strong> California residents may opt out
              of the "sale" of their personal data.
            </li>
          </ul>

          <p className='text-gray-700'>
            To exercise any of these rights, please contact us as outlined in
            the "Contact Information" section.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            9. Third-Party Websites and Services
          </h3>
          <p>
            Our Services may contain links to third-party sites. We are not
            responsible for the privacy practices of third parties and recommend
            reviewing their privacy policies before use.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            10. Policy Updates
          </h3>
          <p>
            We reserve the right to update this Privacy Policy as required.
            Significant changes will be communicated via email or by posting on
            our Site. Your continued use of the Services indicates acceptance of
            the updated policy.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            11. Children's Privacy
          </h3>
          <p>
            Our Services are intended for users over the age of 13. We do not
            knowingly collect personal data from children. If we become aware
            that a child's data has been collected, we will delete it promptly.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            12. International Data Transfers
          </h3>
          <p>
            Your information may be transferred and processed in countries other
            than your own. By using our Services, you consent to this transfer
            of data to India, the United States, or other jurisdictions where
            our service providers operate.
          </p>

          <h3 className='text-xl font-semibold text-[#002169] pt-6'>
            13. Additional Notices for California Residents
          </h3>
          <p>
            Under the California Consumer Privacy Act (CCPA), California
            residents have the right to request access, deletion, and
            information about the data we collect. To exercise these rights,
            please contact us as outlined in this Privacy Policy.
          </p>
          <div className="text-black text-2xl font-semibold font-['Proxima Nova'] leading-snug pt-6">
            Additional Privacy Policy Section for CSDLC
          </div>
          <section>
            <h2 className='text-[#002169] text-xl font-bold pt-6'>
              1. Collection of Personal Information
            </h2>
            <p className='text-gray-600 text-base pt-6'>
              This User-Defined Policy ("Policy") is established by VaidhyaMegha
              Private Limited ("Company," "we," "us," or "our") and governs the
              use of all products and services provided by VaidhyaMegha
              ("Services") to users globally ("you," "your"). By accessing our
              products and services, you agree to be bound by the terms outlined
              in this Policy, including compliance with all applicable local,
              national, and international laws. This Policy applies to all
              users, regardless of location.
            </p>
            <p className='text-gray-600 text-base pt-6'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>
                  Types of Information Collected:
                </li>
                <ul className='list-disc pl-6 text-gray-600 pt-2 '>
                  <li>
                    Personal Identifiable Information (PII): Full name, age,
                    gender, country, address, email, phone number, Aadhaar
                    number (India-specific), and subscriber ID.{' '}
                  </li>
                  <li>
                    Sensitive Information: Medical history, family medical
                    history, allergies, medications, previous surgeries or
                    procedures, and lifestyle information such as dietary
                    habits, smoking status, alcohol consumption, and substance
                    use.
                  </li>
                  <li>
                    Clinical Data: Diagnostic reports, recent lab results,
                    height, weight, BMI, and other related information.
                  </li>
                  <li>
                    {' '}
                    Additional Information: Participation availability and
                    consent to use data for research or analytics. How
                    Information is Collected: Directly from users via forms
                    submitted through the CSDLC application. File uploads,
                    including diagnostic reports, saved locally or in an AWS S3
                    bucket.
                  </li>
                  <br />
                </ul>
                <li className='font-semibold'>
                  Types of Information Collected:
                </li>
                <ul className='list-disc pl-6 text-gray-600 pt-2'>
                  <li>
                    Directly from users via forms submitted through the CSDLC
                    application
                  </li>
                  <li>
                    File uploads, including diagnostic reports, saved locally or
                    in an AWS S3 bucket
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              2. Purpose of Data Collection
            </h2>

            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Primary Use Cases:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    To create and maintain user profiles for clinical or
                    research purposes.
                  </li>
                  <li>
                    To enable users to update, retrieve, and manage their
                    demographic, medical, and lifestyle data
                  </li>
                  <li>
                    To analyze and aggregate clinical data for improving
                    services, generating insights, or providing value-added
                    healthcare analytics..
                  </li>
                  <li>
                    To securely store diagnostic reports for accessibility and
                    further processing.
                  </li>
                  <br />
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              3. Data Sharing and Disclosure
            </h2>

            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Third-Party Sharing:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Diagnostic reports and other files may be stored and
                    accessed through AWS S3 for production environments.
                  </li>
                  <li>
                    Personal data will not be shared with third parties unless:
                    use.
                    <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                      <li>Required by law.</li>
                      <li>Explicit consent is provided by the user.</li>
                    </ul>
                  </li>
                  <li>
                    Temporary storage of data in Redis for user session
                    continuity or form recovery.
                  </li>

                  <br />
                </ul>
                <li className='font-semibold'>Internal Use:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2'>
                  <li>
                    Data is accessed internally for creating profiles, resolving
                    user requests, or performing analysis relevant to CSDLC
                    services.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              4. Data Retention Policy
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Retention Period:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Personal and clinical data are retained as long as the user
                    profile remains active.
                  </li>
                  <li>
                    Diagnostic reports and uploaded files are retained for up to
                    [specify period, e.g., 7 years] for compliance and research
                    purposes.
                  </li>
                  <li>
                    Temporary data saved in Redis is retained for 24 hours for
                    operational continuity.
                  </li>

                  <br />
                </ul>
                <li className='font-semibold'>Deletion Policy:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2'>
                  <li>
                    Users can request data deletion, except where retention is
                    mandated by applicable laws.
                  </li>
                  <li>
                    Deleted data is permanently removed from the database, Redis
                    storage, and AWS S3 (if applicable).
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              5. Data Security Measures
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Protection Methods:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    All sensitive data is encrypted in transit (TLS/SSL) and at
                    rest.
                  </li>
                  <li>
                    Regular audits and access controls are implemented to
                    prevent unauthorized access to data.
                  </li>
                  <li>
                    Uploaded files, including diagnostic reports, are securely
                    stored in AWS S3 or a local secure storage system with
                    restricted access.
                  </li>

                  <br />
                </ul>
                <li className='font-semibold'>File Security:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2'>
                  <li>
                    Diagnostic reports are accessible only via signed URLs,
                    ensuring limited and time-bound access.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              6. User Rights
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Access and Correction:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Users can access their personal and clinical data through
                    the CSDLC application and request corrections.
                  </li>
                </ul>
                <li className='font-semibold'>
                  Deletion and Consent Withdrawal:
                </li>
                <ul className='list-disc pl-6 text-gray-600 mt-2'>
                  <li>
                    Users can withdraw consent and request deletion of their
                    data, except where retention is legally required.
                  </li>
                </ul>
                <li className='font-semibold'>
                  Deletion and Consent Withdrawal:
                </li>
                <ul className='list-disc pl-6 text-gray-600 mt-2'>
                  <li>
                    Users can request an export of their profile, including all
                    associated data, in a machine-readable format.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              7. Use of Tracking Technologies
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Tracking in CSDLC:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Cookies and similar technologies are used to remember user
                    preferences and session states.
                  </li>
                  <li>
                    Analytics tools (e.g., Google Analytics) may track aggregate
                    usage data for application improvement.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              8. Storage and International Data Transfers
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Storage Locations:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    User data may be stored in secure databases within India or
                    other jurisdictions where the application operates.
                  </li>
                  <li>
                    Diagnostic reports and related files are stored on AWS S3
                    servers, which may involve cross-border data transfers.
                  </li>
                </ul>
                <li className='font-semibold'>Transfer Mechanisms:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Cross-border data transfers are protected through Standard
                    Contractual Clauses or equivalent safeguards to comply with
                    applicable laws.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              9. Data Usage for Research and Analytics
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Aggregated Data Analysis:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    De-identified and aggregated data may be used for research
                    and analysis to improve healthcare outcomes and service
                    delivery.
                  </li>
                  <li>
                    No personally identifiable information is included in
                    research reports or shared externally.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              10. Temporary Data Storage
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Redis Usage:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Form data is temporarily stored in Redis for a seamless user
                    experience and is deleted automatically after 24 hours.
                  </li>
                  <li>
                    Redis data is accessible only for operational purposes and
                    is encrypted during storage and transmission.
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <section>
            <h2 className='text-[#002169] text-xl font-semibold pt-6'>
              11. Compliance and Legal Obligations
            </h2>
            <p className='text-gray-600 text-base mt-2'>
              <ul className='list-disc pl-6 text-gray-600'>
                <li className='font-semibold'>Consent for Sensitive Data:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    Collection and processing of sensitive data, such as medical
                    history and diagnostic reports, are performed only with
                    explicit user consent.
                  </li>
                </ul>
                <li className='font-semibold'>Regulatory Compliance:</li>
                <ul className='list-disc pl-6 text-gray-600 mt-2 '>
                  <li>
                    The application complies with applicable laws, including
                    India’s PDP Bill (when applicable), GDPR, and US privacy
                    regulation
                  </li>
                </ul>
              </ul>
            </p>
          </section>
          <h3 className='text-xl font-semibold text-[#002169] pt-2'>
            Contact Us
          </h3>
          <div>
            For any questions, concerns, or to exercise your privacy rights,
            please contact us at:
          </div>
          <p>
            VaidhyaMegha Private Limited
            <br />
            3-5-139/3/3/2, Shiva Nagar Colony, Attapur, Hyderguda, Hyderabad,
            Telangana 500048, India.
            <br />
            Phone: +91 9618986039
            <br />
            Email: contact@vaidhyamegha.com
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
