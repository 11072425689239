import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button'; // Adjust the path as necessary
import Spinner from '../../components/spinner'; // Adjust the path as necessary
import { AuthContext } from '../../context/AuthContext';

const Register = () => {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [countries, setCountries] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('/contries.json');
        const data = await response.json();

        setCountries(
          data.map((country) => ({
            name: country.name.toLowerCase(),
            flag: country.flag,
            code: country.code.toLowerCase()
          }))
        );
      } catch (err) {
        console.error('Failed to fetch countries:', err);
      }
    };
    fetchCountries();
  }, []);
  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        navigate('/auth/login');
      }, 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const searchLower = searchTerm.toLocaleLowerCase();

  const filteredCountries = countries.filter(
    (c) =>
      c.name.startsWith(searchLower) ||
      c.code.startsWith(searchLower) ||
      c.name.includes(searchLower) ||
      c.code.includes(searchLower)
  );
  const validateForm = () => {
    if (!fullName || !email || !password || !searchTerm || !confirmPassword) {
      setError('All fields are required.');
      return false;
    }

    // Full Name Validation: Only letters and spaces allowed
    const nameRegex = /^(?:No|[A-Za-z\d](?:[A-Za-z\d ]{0,248}[A-Za-z\d])?)$/;
    if (!nameRegex.test(fullName) || /\s{2,}/.test(fullName)) {
      setError(
        'Full Name cannot start with a space, contain consecutive spaces, and must only contain letters, numbers, spaces (max 250 characters).'
      );
      return false;
    }

    // Improved Email Validation Regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email format.');
      return false;
    }

    // Password Validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/;
    if (!passwordRegex.test(password)) {
      setError(
        'Password must be 8-25 characters and include an uppercase letter, lowercase letter, number, and special character.'
      );
      return false;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }

    // Country Validation: Allow valid countries and custom input
    const countryNames = countries.map((c) => c.name.toLowerCase()); // Get valid country names in lowercase
    if (!searchTerm.trim()) {
      setError('Country is required.');
      return false;
    }

    if (
      !countryNames.includes(searchTerm.toLowerCase()) &&
      searchTerm.length < 2
    ) {
      setError(
        'Invalid country name. Please select from the list or enter a valid country.'
      );
      return false;
    }

    setError('');
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Check validation
    if (!validateForm()) return;

    setLoading(true);
    setError('');
    try {
      await register({
        fullName,
        email,
        password
      });

      setIsSuccess(true);
      setFullName('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setSearchTerm('');
    } catch (err) {
      // --- CUSTOM HANDLING FOR 501 STATUS OR ANY OTHER ---
      // If your backend specifically sends a 501 code to indicate
      // the email is already in use, handle it here:
      if (err?.message?.includes('409')) {
        setError(
          'Email is already registered. Please use a different email or log in.'
        );
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex justify-center w-full min-h-screen'>
      <div className='w-full max-w-xl mx-auto xl:p-0 sm:p-8 md:p-0'>
        {error && (
          <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
            <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
              {error}
            </p>
          </div>
        )}
        {isSuccess && (
          <div className='mb-4 p-4 bg-green-50 border border-green-200 rounded-md'>
            <p className="text-green-600 text-sm font-['Proxima Nova'] text-center">
              <span className='font-bold'>Registration successful..! </span>
              "Please check your inbox to verify your email."
            </p>
          </div>
        )}
        <div className='w-full'>
          <form
            onSubmit={handleRegister}
            className='bg-[#f9fffe] rounded-[10px] shadow-md p-6 sm:p-8 md:p-10 border border-[#d0ebff] mx-auto'
          >
            {/* Full Name Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Full Name<span className='text-[#e63a52]'>*</span>:
              </label>
              <input
                type='text'
                className='
                    xl:w-3/4
                    xxl:w-3/4
                    lg:w-3/4
                    sm:w-3/4
                    md:w-3/4
                    mb:w-full 
                   h-10 bg-white pl-[10px] rounded-md shadow-inner border border-[#b3e9f4]'
                placeholder='Enter your Full Name'
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            {/* Email Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Email Address<span className='text-[#e63a52]'>*</span>:
              </label>
              <input
                type='email'
                className='
                    sm:w-3/4
                   h-10 bg-white pl-[10px] rounded-md shadow-inner border border-[#b3e9f4]'
                placeholder='Enter your Email Address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* Password Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className=' sm:w-3/4'>
                <input
                  id='hs-toggle-password'
                  type={showPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[10px] pr-14 rounded-md shadow-inner border 
                    ${
                      password && confirmPassword && password.length >= 8
                        ? password === confirmPassword
                          ? 'border-green-500'
                          : 'border-red-500'
                        : 'border-[#b3e9f4]'
                    }`}
                  placeholder='Enter your Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute inset-y-0 right-2 sm:mt-0 mt-8 flex items-center  px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle
                          cx='12'
                          cy='12'
                          r='3'
                        ></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line
                          x1='2'
                          x2='22'
                          y1='2'
                          y2='22'
                        ></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>

            {/* Confirm Password Field */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label className='w-1/2 sm:pb-0 pr-2 text-[#2685ce] text-base font-medium'>
                Confirm Password<span className='text-[#e63a52]'>*</span>:
              </label>
              <div className='relative w-full sm:w-3/4'>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={`w-full h-10 bg-white pl-[10px] pr-10 rounded-md shadow-inner border 
                        ${
                          password && confirmPassword && password.length >= 8
                            ? password === confirmPassword
                              ? 'border-green-500'
                              : 'border-red-500'
                            : 'border-[#b3e9f4]'
                        }`}
                  placeholder='Confirm your Password'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type='button'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className='absolute inset-y-0 right-2 flex items-center px-3 cursor-pointer text-gray-400 rounded-md focus:outline-none focus:text-blue-600'
                >
                  <svg
                    className='shrink-0 size-3.5'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    {showConfirmPassword ? (
                      <>
                        <path d='M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z'></path>
                        <circle
                          cx='12'
                          cy='12'
                          r='3'
                        ></circle>
                      </>
                    ) : (
                      <>
                        <path d='M9.88 9.88a3 3 0 1 0 4.24 4.24'></path>
                        <path d='M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68'></path>
                        <path d='M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61'></path>
                        <line
                          x1='2'
                          x2='22'
                          y1='2'
                          y2='22'
                        ></line>
                      </>
                    )}
                  </svg>
                </button>
              </div>
            </div>
            {/* Country Selector */}
            <div className='relative mb-4 flex max-w-[400px] sm:ml-[42px] flex-col sm:flex-row sm:justify-center sm:items-center sm:text-right'>
              <label
                className='w-1/2 pb-0
                pr-2 text-[#2685ce] text-base font-medium'
              >
                Country<span className='text-[#e63a52]'>*</span>:
              </label>
              <div
                className='
                    sm:w-3/4
                
                 h-10 bg-white  rounded-md shadow-inner border'
              >
                {/* Search Input with Flag */}
                <input
                  type='text'
                  placeholder='Search your Country...'
                  className=' h-[40px] bg-white rounded-md pl-[10px] pb-[2px]  w-full sm:w-[240px] shadow-inner border border-[#b3e9f4]'
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setDropdownOpen(true);
                    if (e.target.value === '') {
                      setSelectedCountry(null);
                    }
                  }}
                  onClick={() => setDropdownOpen(true)} // Allow reopening when clicked
                  onFocus={() => setDropdownOpen(true)}
                  onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
                />
                {selectedCountry && (
                  <button
                    className='absolute right-5  sm:top-2 top-10 text-gray-500 hover:text-gray-700'
                    onClick={() => {
                      setSelectedCountry(null);
                      setSearchTerm('');
                      setDropdownOpen(true);
                    }}
                  >
                    ✕
                  </button>
                )}
              </div>

              {/* Dropdown List */}
              {dropdownOpen && (
                <ul className='absolute sm:w-[240px] sm:ml-[160px] w-[300px] ml-[0px] bg-white border border-gray-300 rounded-md shadow-md max-h-72 overflow-y-auto sm:mt-[330px] mt-[75px] z-10'>
                  {filteredCountries.length > 0 &&
                    filteredCountries.map((c, index) => (
                      <li
                        key={index}
                        className='p-2 flex items-center hover:bg-gray-100 cursor-pointer'
                        onMouseDown={(e) => {
                          e.preventDefault();
                          setSelectedCountry(c);
                          setSearchTerm(`${c.flag} ${c.name.toUpperCase()}`);
                          setDropdownOpen(false);
                        }}
                      >
                        <span className='ml-[40px] pr-4 text-lg'>{c.flag}</span>{' '}
                        {c.name}
                      </li>
                    ))}
                </ul>
              )}

              {/* <div className='absolute top-1/2 right-4 transform -translate-y-1/2 pointer-events-none'>
                    <img
                      src='/register/dropdown.png'
                      alt='Dropdown Arrow'
                      className='h-[10.5px] w-[14px]'
                    />
                  </div> */}
            </div>

            {/* Register Button */}
            <div
              className='mb-1 flex flex-col
                sm:flex-row sm:justify-center sm:text-right'
            >
              <label
                className='w-2/4 
      

                  pr-2 text-[#2685ce] text-base font-medium'
              />
              <div
                className='mb-2 flex sm:ml-1 ml-0
                    sm:w-3/4'
              >
                {/* <button
                    type="submit"
                    className="h-[46px] 
                  px-[30px] py-3 bg-[#1876be] text-white rounded-md inline-flex font-semibold justify-center items-center gap-2.5"
                    disabled={loading}
                  > */}
                <Button
                  label='REGISTER'
                  disabled={loading}
                  variant='csdlc_button'
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <p className='text-lg font-semibold'>Register</p>
                  )}
                </Button>
              </div>
            </div>

            {/* Already have an account? */}
            <div
              className='mb-0 flex flex-col
                sm:flex-row sm:justify-center sm:text-right 
'
            >
              <label
                className='w-2/4 pb-2

                  pr-2 text-[#2685ce] text-base font-medium'
              ></label>
              <div
                className='mb-2 flex sm:ml-2 ml-0
                              sm:w-3/4

                '
              >
                <span
                  className='text-[#3e3e3e] text-sm 
                font-normal'
                >
                  Already have an account?{' '}
                </span>
                <a
                  href='/auth/login'
                  className='text-[#2685ce] text-sm font-semibold underline ml-1'
                >
                  Log In
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
