import React from 'react';
import Footer from './Footer';
import FrameComponent from './FrameComponent';

const Layout = ({ children, isLoggedIn, setIsLoggedIn }) => {
  return (
    <div className='w-full overflow-x-hidden flex flex-col min-h-screen'>
      <FrameComponent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <main className='flex-1 w-full sm:pt-[100px] pt-[80px]'>{children}</main>

      <Footer />
    </div>
  );
};

export default Layout;
