import { useClient } from '@vaidhyamegha/service-gateway-react';
import React, { useState } from 'react';

const ForgotPassword = () => {
  const client = useClient();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setEmailError('');

    if (!email) {
      setEmailError('Email is required');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      const response = await client.forgotPassword(email);
      setMessage(response.message);
    } catch (err) {
      if (err?.message?.includes('403')) {
        setError('Your email is not verified');
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <div className='flex justify-center sm:mt-32 mt-20 w-full mx-auto min-h-screen'>
      <div className='w-full max-w-lg mx-auto xl:p-0 sm:p-8 md:p-0'>
        {error && (
          <div className='mb-4 p-4 bg-red-50 border border-red-200 rounded-md'>
            <p className="text-red-600 text-sm font-['Proxima Nova'] text-center">
              {error}
            </p>
          </div>
        )}
        <div className='bg-white shadow-md rounded-lg p-8 w-full max-w-lg border-2 border-blue-300 flex flex-col items-center'>
          <h2 className='text-2xl font-bold text-[#1876be] text-center mb-6'>
            Provide your Email
          </h2>
          {message ? (
            <div className='bg-green-100 text-green-800 p-4 rounded-md text-center'>
              <p>
                Password reset link has been sent to your{' '}
                <a
                  href={`mailto:${email}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-[#1876be] hover:underline'
                >
                  email
                </a>
                .
              </p>
              <p className='mt-2'>
                If you don&apos;t see the email, please check your spam folder
                or{' '}
                <a
                  href='/forgot-password'
                  className='text-[#1876be] hover:underline'
                >
                  try requesting a new one
                </a>
                .
              </p>
            </div>
          ) : (
            <>
              <div className='w-full mb-4 flex sm:flex-row flex-col'>
                <label
                  htmlFor='email'
                  className='text-[#1876be] font-medium pr-4 mb-2 mt-2'
                >
                  Email<span className='text-red-500 pr-2'>*</span>:
                </label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 bg-blue-50 shadow-inner'
                />
              </div>
              {emailError && (
                <div className='text-red-600 text-sm mb-2 text-center'>
                  {emailError}
                </div>
              )}
              <div className='w-full flex justify-center mr-[170px] mb-4'>
                <button
                  onClick={handleForgotPassword}
                  className='w-32 bg-[#1876be] text-white font-semibold py-2 rounded-md hover:bg-blue-700 transition'
                >
                  SUBMIT
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
