import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../utils/analytics';
import FaqSection from './faq';

const steps = [
  {
    number: '1',
    title: 'Register for Free',
    description: 'Create an account to start exploring the platform.'
  },
  {
    number: '2',
    title: 'Log In to Your Dashboard',
    description:
      'Access your personalized dashboard to view available features.'
  },
  {
    number: '3',
    title: 'Subscribe for Full Access',
    description:
      'Unlock premium tools and insights by subscribing to our platform.'
  },
  {
    number: '4',
    title: 'Seamless Payment',
    description:
      'Complete your subscription securely and gain full access to advanced features.'
  },
  {
    number: '5',
    title: 'Access Reports and More',
    description:
      'Download and explore multiple reports tailored to your needs. Stay tuned as we continue adding new features to enhance your experience.'
  }
];

const StepNumber = ({ number }) => (
  <div className='w-12 h-12 flex items-center justify-center rounded-full bg-teal-400 shrink-0'>
    <span className='font-bold text-lg md:text-xl lg:text-2xl text-black'>
      {number}
    </span>
  </div>
);

const Step = ({ number, title, description }) => (
  <div className='flex gap-4 mt-6 items-start'>
    <StepNumber number={number} />
    <div>
      <strong className='text-base lg:text-lg text-zinc-600'>{title}</strong>
      <p className='text-sm lg:text-base text-zinc-600'>{description}</p>
    </div>
  </div>
);

const SubscriptionLandingpage = () => {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);

  const handleButtonClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/auth/register');
    }, 2000);
  };
  const handleYouTubeClick = () => {
    trackEvent('youtube_video_play', {
      content_type: 'video',
      item_id: 'BQZFJUj9jJc'
    });
    window.open('https://www.youtube.com/watch?v=BQZFJUj9jJc', '_blank');
    trackEvent('youtube_link_open', {
      content_type: 'link',
      item_id: 'BQZFJUj9jJc'
    });
  };

  return (
    <div className='w-full bg-white py-10'>
      {/* Responsive Container with Conditional Margins */}
      <div
        className='
        w-full 
        mb:w-full 
        sm:w-full 
        md:w-[90%] 
        lg:max-w-[calc(100%-200px)] 
        lg:mx-auto 
        mb:px-4
        sm:px-4 md:px-4 lg:px-0 xl:px-0 xxl:px-0
    
        flex flex-col md:flex-row justify-between gap-20
      '
      >
        {/* Left Column: Steps and Welcome Text */}
        <div className='flex-1'>
          <h1 className='text-2xl font-semibold text-[#4299d4]'>
            Welcome to CSDLC.org
          </h1>
          <p className='mt-4 text-base text-zinc-600'>
            <strong>
              Your Gateway to Smarter Clinical Research and Data Insights
            </strong>
            <br />
            Discover how our platform can revolutionize your clinical trial
            experience. From seamless trial management to advanced analytics,
            CSDLC is designed to streamline your research and deliver actionable
            insights tailored to your needs.
          </p>

          <h2 className='mt-8 text-2xl font-semibold text-[#4299d4]'>
            How It Works
          </h2>
          <div className='mt-4'>
            {steps.map((step) => (
              <Step
                key={step.number}
                number={step.number}
                title={step.title}
                description={step.description}
              />
            ))}
          </div>
          <button
            className='mt-8 px-8 py-4 mb-6 bg-teal-500 text-white font-bold uppercase rounded-md hover:bg-teal-600'
            aria-label='Get Started'
            onClick={handleButtonClick}
            disabled={isloading}
          >
            {isloading ? 'LOADING...' : "LET'S GET STARTED"}
          </button>
        </div>

        {/* Right Column: Video and Unlock Section */}
        <div className='flex-1'>
          <h2 className='text-2xl font-bold text-[#4299d4]'>
            Unlock the Future of Clinical Research with CSDLC!
          </h2>
          <p className='mt-4 text-sm text-zinc-600'>
            Stay ahead in the ever-evolving world of clinical trials. By
            subscribing to CSDLC, you gain exclusive access to in-depth reports,
            cutting-edge knowledge graphs, and personalized trial
            recommendations tailored just for you.
          </p>
          <p className='mt-4 text-sm text-zinc-600'>
            Elevate your research, make informed decisions, and be part of a
            global community driving innovation in healthcare.
          </p>
          <p className='mt-4 text-sm text-zinc-600'>
            Your journey towards deeper insights and impactful research starts
            here. Subscribe today and empower your clinical research like never
            before!
          </p>

          <div className='mt-6'>
            <iframe
              className='w-full h-[410px] rounded-md shadow-md'
              src='https://www.youtube.com/embed/BQZFJUj9jJc?playlist=BQZFJUj9jJc&loop=1&rel=0'
              title='CSDLC Video'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              onClick={handleYouTubeClick}
            />
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <FaqSection />
    </div>
  );
};

export default SubscriptionLandingpage;
