import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // or appropriate hook for your router
import Login from '../pages/login/login';
import Register from '../pages/registration/register';

const MainAuth = () => {
  const location = useLocation();
  const [isRegister, setIsRegister] = useState(true);

  useEffect(() => {
    // If current path includes '/login', set isRegister to false
    if (location.pathname.includes('/login')) {
      setIsRegister(false);
    } else {
      setIsRegister(true);
    }
  }, [location.pathname]);

  const toggleForm = (shouldRegister) => {
    setIsRegister(shouldRegister);
  };

  return (
    <div className='flex justify-center w-full min-h-screen mt-6 '>
      <div className='w-full max-w-xl  pt-4 mb:pt-6 sm:pt-8 xxl:pb-0 md:pt-2'>
        {/* Header and toggles */}
        <div className='flex xl:justify-left xxl:justify-left lg:justify-left md:justify-start sm:justify-center mb:justify-center space-x-4 mb-1'>
          <span
            className={`text-lg md:text-2xl ml-0 font-semibold cursor-pointer ${
              isRegister ? 'text-[#2685ce]' : 'text-[#bebebe]'
            }`}
            onClick={() => toggleForm(true)}
          >
            Register Now!
          </span>
          <span className='text-gray-500 text-lg md:text-2xl font-normal'>
            |
          </span>
          <span
            className={`text-lg md:text-2xl font-semibold cursor-pointer ${
              isRegister ? 'text-[#bebebe]' : 'text-[#2685ce]'
            }`}
            onClick={() => toggleForm(false)}
          >
            Login Now!
          </span>
        </div>

        {/* Form Container */}
        <div className='mt-4'>{isRegister ? <Register /> : <Login />}</div>
      </div>
    </div>
  );
};

export default MainAuth;
