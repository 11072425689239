import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = ({ className = '' }) => {
  const navigate = useNavigate();

  const handleNavigation = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <footer
      className={`bg-[#0C2D56] text-white text-sm font-sans mx-auto w-full ${className}`}
    >
      <div className='container-full sm:mx-auto lg:mx-[76px]   py-6 px-4 sm:px-6  flex flex-col sm:flex-row content-center sm:justify-center justify-between border-tborder-[#ffffff33]'>
        {/* Left Section - Logo */}
        <div className='flex sm:justify-start  w-full sm:w-1/3'>
          <img
            className='h-[67px] w-[230px] object-contain'
            alt='CSDLC logo'
            src='/footer/logo.svg'
          />
        </div>

        {/* Middle Section - Certifications & Text */}
        <div className='flex flex-col mt-2  w-full sm:w-1/3 text-left  sm:border-l sm:border-r border-[#ffffff33] sm:px-8'>
          <p className='text-sm'>
            CSDLC.org is Powered by VaidhyaMegha Pvt Ltd., an ISO 27001:2022 &
            ISO 9001:2015 Certified company
          </p>
          <div className='flex items-center justify-start  gap-3 mt-4 mb-4'>
            <img
              className='h-[89px] w-[90px] object-contain'
              alt='ISO 9001'
              src='/subscription/iso-90012015-1@2x.png'
            />
            <img
              className='h-[89px] w-[90px] object-contain'
              alt='ISO 27001'
              src='/subscription/iso-270012022-3@2x.png'
            />
          </div>
        </div>

        {/* Right Section - Contact Information (Left Aligned) */}
        <div className='w-full sm:w-1/3 sm:ml-8 mt-2  sm:mt-0 sm:text-left text-left '>
          <h3 className='text-md font-semibold text-[#30d5c8]'>Reach us</h3>
          <div className='flex flex-col text-sm gap-1 mt-2'>
            <a
              href='tel:+919618986039'
              className='text-white hover:underline'
            >
              <img
                className='inline-block w-4 h-4 mr-2'
                src='/subscription/vector-4.svg'
                alt='Phone'
              />{' '}
              +91 9618986039
            </a>
            <a
              href='mailto:contact@vaidhyamegha.com'
              className='text-white hover:underline'
            >
              <img
                className='inline-block w-4 h-4 mr-2'
                src='/subscription/vector-5.svg'
                alt='Email'
              />{' '}
              contact@vaidhyamegha.com
            </a>
            <a
              href='https://maps.app.goo.gl/LG2HvxSpdAAYKFQC7'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white sm:hover:underline'
            >
              <img
                className='inline-block w-4 h-4 mr-2'
                src='/subscription/location.svg'
                alt='Location'
              />{' '}
              3-5-139/3/3/2, Shiva Nagar Colony, Attapur, Hyderguda, Hyderabad,
              Telangana 500048, India.
            </a>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className='mt-0 text-xs text-[#30d5c8] border-t border-[#ffffff33] py-2 flex flex-col sm:flex-row justify-between items-center text-left mx-0'>
        <div className='text-left sm:mx-[100px] mx-4 '>
          © Copyright 2018 - 25 VaidhyaMegha Pvt. Ltd. All Rights Reserved.
        </div>
        <div className='text-center mx-4'>
          A Product from{' '}
          <span className='font-bold'>
            {' '}
            <a href='http://www.vaidhyamegha.com'>VaidhyaMegha</a>
          </span>
        </div>
        <div className='flex gap-1 sm:mx-[100px] mx-2 py-2 text-left  px-0'>
          <a
            href='/privacy-policy'
            onClick={(e) => handleNavigation(e, '/privacy-policy')}
            className='hover:underline'
          >
            Privacy Policy
          </a>
          <span className='text-transparent sm:text-[#30d5c8]'>|</span>
          <a
            href='/terms-and-conditions'
            onClick={(e) => handleNavigation(e, '/terms-and-conditions')}
            className='hover:underline'
          >
            {' '}
            Terms & Conditions
          </a>
          <span className='text-transparent sm:text-[#30d5c8]'>|</span>
          <a
            href='/refund-policy'
            onClick={(e) => handleNavigation(e, '/refund-policy')}
            className='hover:underline'
          >
            {' '}
            Refund and Cancellation Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
