// import React from "react";

const Button = ({
  label,
  onClick,
  variant = 'primary',
  children,
  disabled
}) => {
  const styles = {
    primary: 'bg-blue-500 text-white',
    secondary: 'bg-gray-500 text-white',
    danger: 'bg-[#ff5454] text-white',
    csdlc_button:
      'bg-[#1876be] text-[#ffffff] hover:bg-[#0c2d56] hover:text-white'
  };

  return (
    <button
      className={`sm:h-[46px] h-[46px] sm:px-[20px] px-10 py-6 rounded-md inline-flex font-semibold justify-center sm:text-md text-sm uppercase items-center ${styles[
        variant
      ] || styles.primary} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children ? children : label}
    </button>
  );
};

export default Button;
