import PropTypes from 'prop-types';
import React from 'react';

const DictionarySearch = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className='w-full flex justify-center items-center mb-8'>
      {/* 
        Use Tailwind's 'sr-only' class for screen reader text, 
        replacing your '.visually-hidden' class
      */}
      <label htmlFor='search-input' className='sr-only'>
        Search clinical terminology
      </label>

      <input
        id='search-input'
        type='text'
        placeholder='Search terms...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className='
          w-full
          max-w-[600px]
          px-3 py-2
          border border-[#80D6E9]
          rounded
          text-base
          bg-[#F2FFFE]
          shadow-inner
          outline-none
          focus:ring-2 focus:ring-[#2685CE]
        '
        aria-label='Search clinical terminology'
        aria-controls='search-results'
        aria-describedby='search-description'
      />

      {/* Screen reader text for additional context */}
      <span id='search-description' className='sr-only'>
        Type to search through clinical terms and descriptions
      </span>
    </div>
  );
};

DictionarySearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
};

export default DictionarySearch;
